export function getBreakpoint() {
    const width = window.innerWidth;
    if (width <= 768) {
        return 'tablet'; // Medium screens (tablets in portrait mode)
    } else if (width <= 1024) {
        return 'tablet-landscape'; // Larger tablets and small laptops
    } else if (width <= 1200) {
        return 'desktop-small'; // Small desktops or large tablets
    } else if (width >= 1200 && width < 1300) {
        return 'desktop-small-up'; // Small desktops or large tablets
    }else if (width >= 1200 && width <= 1876) {
        return 'desktop-exception';
    }  else if (width >= 1300 && width <= 1600) {
        return 'desktop-higher';
    } else if (width >= 1600 && width <= 1920) {
        return 'desktop-perfect';
    } else {
        return 'desktop'; // Larger desktops and wide screens
    }

}


