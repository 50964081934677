import React, { Fragment, useEffect, useRef } from 'react'
import style from "./EmployeeDetails.module.css"
import ctl from "../../images/Ellipse 334.svg"
import ptl from "../../images/Ellipse 332.svg"
import rtl from "../../images/rt-logo.svg"
import fk1 from "../../images/fk-lift.svg"
import sdi1 from "../../images/sdimg-2.svg"
import sdi2 from "../../images/sdimg-3.svg"
import sdi3 from "../../images/sdimg-4.svg"
import sdi4 from "../../images/sdimg-5.svg"
import sdi5 from "../../images/sdimg-6.svg"
import sdi6 from "../../images/sdimg-7.svg"
import sdi7 from "../../images/sdimg-8.svg"
import sdi8 from "../../images/sdimg-9.svg"
import sdi9 from "../../images/sdimg-10.svg"
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import close from "../../images/close.svg"
import { last7Days, getRandomArbitrary, convertToMinutes } from '../../util/chartUtil'


function EmployeeDetails({ activeEmployee, handleDetailCardClose }) {
    const myRef = useRef(null);
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' });
    useEffect(() => {
        executeScroll();
    }, [activeEmployee]);

    useEffect(() => {
        document.body.classList.add('jw-modal-open');

        return () => document.body.classList.remove('jw-modal-open');
    }, []);

    const startDate = new Date('2024-10-30');
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() - 6);


    return (
        <>
            <div id="modal-1" className="jw-modal open">
                <div className="jw-modal-body">
                    <div className='cd-container-employee-details' style={{ zIndex: '9999', position: 'relative' }}
                        ref={myRef}>
                        <div className="cd-container-inner">
                            <div style={{ float: 'right', cursor: 'pointer' }}
                                onClick={() => handleDetailCardClose(true)}>
                                <img src={close} alt="" />
                            </div>
                            <div className={style['sec-1']}>
                                <div className={style['wkr-img']}>
                                     <img src={require(`../../images/${activeEmployee.image_raw}`)} alt=""/>
                                    <p className='wkr-name' title={activeEmployee.worker_name}>
                                        {activeEmployee.worker_name ?? activeEmployee.name}</p>
                                    <div className={style['wkr-m-wrapper']}><p
                                        className={style['wkr-id']}>{activeEmployee.worker_id ?? activeEmployee.employee_id}</p>
                                        <span>|</span>
                                        <p className={style['wkr-id']}>
                                            {activeEmployee.worker_designation ?? activeEmployee.role}</p>
                                        <span>|</span>
                                        <p className={style['wkr-id']}>{activeEmployee.worker_team ?? activeEmployee.team}</p>
                                    </div>

                                </div>
                                <div className={style['wkg-details']}>
                                    <div className="wkgd1">
                                        <p className="wkgdp1a">
                                            {activeEmployee?.worker_remaining_tasks ?? activeEmployee.taskProgress.remainingTasks}
                                            /
                                            {activeEmployee?.worker_completed_tasks ?? activeEmployee.taskProgress.completedTasks}
                                        </p>
                                        <PieChart
                                            series={[
                                                {
                                                    data: [
                                                        {
                                                            id: 0,
                                                            value: (parseInt(activeEmployee?.taskProgress?.worker_completed_tasks ?? activeEmployee.taskProgress.completedTasks) / 10) * 100,
                                                            color: '#b3cf2d'
                                                        },
                                                        {
                                                            id: 1,
                                                            value: (parseInt(activeEmployee?.taskProgress?.worker_remaining_tasks ?? activeEmployee.taskProgress.remainingTasks) / 10) * 100,
                                                            color: '#959595'
                                                        },

                                                    ],
                                                    valueFormatter: (v, { dataIndex }) => {
                                                        return `${Math.floor(v.value / 10)}`;
                                                    },
                                                    innerRadius: 60, outerRadius: 48, cx: 65, cy: 58,

                                                },
                                            ]}
                                            width={150}
                                            height={128}
                                        />
                                        <p className="wkgdp2"><img src={ctl} alt="" /> Completed Task
                                            - {activeEmployee?.worker_completed_tasks ?? activeEmployee.taskProgress.completedTasks}</p>
                                        <p className="wkgdp3"><img src={rtl} alt="" /> Remaining Task
                                            - {activeEmployee?.worker_remaining_tasks ?? activeEmployee.taskProgress.remainingTasks}</p>
                                    </div>

                                    <div className="wkgd1">
                                        {(activeEmployee?.scores?.safetyScore) > 50 ? (
                                            <p className={style['p-ninety']}>{activeEmployee?.scores?.safetyScore}</p>
                                        ) : <p className={style['p-fifty']}>{activeEmployee?.scores?.safetyScore}</p>}

                                        <p className="wkgdp2a">Safety</p>
                                        <p className="wkgdp3">Score</p>
                                    </div>

                                    <div className="wkgd1">
                                        {(activeEmployee?.scores?.productivityScore) > 50 ? (
                                            <p className={style['p-ninety']}>{activeEmployee?.scores?.productivityScore}</p>
                                        ) : <p className={style['p-fifty']}>{activeEmployee?.scores?.productivityScore}</p>}

                                        <p className="wkgdp2a">Productivity</p>
                                        <p className="wkgdp3">Score</p>
                                    </div>

                                    <div className="wkgd1">
                                        <p className="wkgdp1-1" style={{ left: '26%' }}>Working <br></br> Hours</p>
                                        <PieChart
                                            series={[
                                                {
                                                    data: [
                                                        { id: 0, value: (convertToMinutes(activeEmployee?.worker_productive_time ?? activeEmployee.workingHours.productiveTime) / 10) * 100, color: '#1c9e29' },
                                                        { id: 1, value: (convertToMinutes(activeEmployee?.worker_idle_time ?? activeEmployee.workingHours.idleTime) / 10) * 100, color: '#f1933b' },
                                                        { id: 2, value: (convertToMinutes(activeEmployee?.worker_remaining_time ?? activeEmployee.workingHours.remainingTime) / 10) * 100, color: '#959595' },
                                                    ],
                                                    hideTooltip: true,
                                                    innerRadius: 60, outerRadius: 48, cx: 60, cy: 60,
                                                },
                                            ]}
                                            width={150}
                                            height={128}
                                        />
                                        <div className="wkgdp1-2" style={{ justifyItems: 'flex-start' }}>
                                            <p className="wkgdp2" ><img src={ctl} alt="" /> Productive
                                                - {activeEmployee?.worker_productive_time ?? activeEmployee.workingHours.productiveTime}</p>
                                            <p className="wkgdp3" ><img src={ptl} alt="" /> Idle
                                                - {activeEmployee?.worker_idle_time ?? activeEmployee.workingHours.idleTime}</p>
                                            <p className="wkgdp3" ><img src={rtl} alt="" /> Remaining
                                                - {activeEmployee?.worker_remaining_time ?? activeEmployee.workingHours.remainingTime}</p>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className='sec-2' style={{ borderBottom: '.5px solid #2B3334', margin: '8px', padding: '20px 30px 10px 20px' }}>
                                <div className='sec-2-1'>
                                    <div className="sec-details-2">
                                        <p className='sd-1'>{activeEmployee?.equipment_name ?? activeEmployee.name}</p>
                                        <p className={style['sd-2']}><img src={fk1} alt="" /> Assigned Equipment</p>
                                    </div>
                                    <div className="sec-details-2">
                                        <p className='sd-1'>{activeEmployee?.worker_location_title ?? activeEmployee.current_location}</p>
                                        <p className={style['sd-2']}><img src={sdi6} alt="" /> Current Location</p>
                                    </div>
                                </div>
                                <div className='sec-2-1'>
                                    <div className="sec-details-2">
                                        <p className='sd-1'>{activeEmployee?.task_name ?? activeEmployee.currentStatus.task}</p>
                                        <p className={style['sd-2']}><img src={sdi1} alt="" /> Current Task</p>
                                    </div>
                                    <div className="sec-details-2">
                                        <p className={`sd-1 ${(activeEmployee?.worker_status ?? activeEmployee.currentStatus.status)  === 'Idle' ? 'idle' : ''}`}>
                                            {activeEmployee?.worker_status ?? activeEmployee.currentStatus.status}
                                        </p>

                                        <p className={style['sd-2']}><img src={sdi7} alt="" /> Status</p>
                                    </div>
                                </div>
                                <div className='sec-2-1'>
                                    <div className="sec-details-2">
                                        {/* <p className='sd-1'>{activeEmployee?.schedule_first_half_start.value}</p> */}
                                        <p className='sd-1'>9</p>
                                        <p className={style['sd-2']}><img src={sdi2} alt="" /> Scheduled Hours</p>
                                    </div>
                                    <div className="sec-details-2">
                                        <p className='sd-1'>{activeEmployee?.worker_body_temperature ?? activeEmployee.healthMetrics.bodyTemperature}</p>
                                        <p className={style['sd-2']}><img src={sdi8} alt="" /> Body Temperature</p>
                                    </div>
                                </div>
                                <div className='sec-2-1'>
                                    <div className="sec-details-2">
                                        <p className='sd-1'>{activeEmployee?.worker_schedule_shift ?? activeEmployee.currentStatus.shift}</p>
                                        <p className={style['sd-2']}><img src={sdi3} alt="" /> Shift</p>
                                    </div>
                                    <div className="sec-details-2">
                                        {activeEmployee?.worker_fatigue_level !== "" &&
                                            <p className='sd-1'>{activeEmployee?.worker_fatigue_level ?? activeEmployee.healthMetrics.fatigueLevel.fatigueAssessmentScore} </p>
                                        }
                                        
                                        <p className={style['sd-2']}><img src={sdi9} alt="" /> Fatigue Level</p>
                                    </div>
                                </div>
                                <div className='sec-2-1'>
                                    <div className="sec-details-2">
                                        <p className='sd-1'>{activeEmployee?.worker_heart_rate ?? activeEmployee.healthMetrics.heartRate}</p>
                                        <p className={style['sd-2']}><img src={sdi4} alt="" /> Heart rate</p>
                                    </div>
                                </div>
                                <div className='sec-2-1'>
                                    <div className="sec-details-2">
                                        <p className='sd-1'>{activeEmployee?.worker_oxygen_level ?? activeEmployee.healthMetrics.oxygenLevel}%</p>
                                        <p className={style['sd-2']}><img src={sdi5} alt="" /> Oxygen Level</p>
                                    </div>
                                </div>
                            </div>


                            <div className={style.sec4}>
                                <div className={style.barSec}>
                                    <p className='b-p'>Safety Violations</p>

                                    <BarChart
                                        series={[
                                            {
                                                data: getRandomArbitrary(),
                                                color: '#A35858'
                                            },

                                        ]}
                                        // leftAxis={null}
                                        height={150}
                                        width={400}
                                        xAxis={[{
                                            data: last7Days(),
                                            scaleType: 'band'
                                        }]}
                                        margin={{ top: 5, bottom: 30, left: 55, right: 0 }}
                                    />

                                </div>

                                <div className={style.barSec2}>
                                    <div className={style.barSec2inner}>
                                        <div className={`${style.bp}`} >Tasks Journey</div>
                                        <div className={`${style.barParent}`}>
                                            <div className={`${style.barWrap}`}>
                                                {activeEmployee.taskJourney.slice(0, 6).map((task, index) => (
                                                    <Fragment key={index}>
                                                        {task.status === 'Completed' && (
                                                            <div className={`${style.ct1} ${style[`ct-box-${index + 1}`]}`}>
                                                            </div>
                                                        )}
                                                        {task.status === 'In Progress' && (
                                                            <div className={`${style.ct2} ${style[`ct-box-${index + 1}`]}`}>
                                                            </div>
                                                        )}
                                                        {task.status !== 'Completed' && task.status !== 'In Progress' && (
                                                            <div className={`${style.ct3} ${style[`ct-box-${index + 1}`]}`}>
                                                            </div>
                                                        )}
                                                    </Fragment>
                                                ))}
                                                {activeEmployee.taskJourney.slice(0, 6).map((task, index) =>
                                                    <Fragment key={index}>
                                                        {task.status === 'In Progress' &&
                                                            <div
                                                                className={`${style.marker} ${style.inProgressMarker} ${style[`marker-${index + 1}`]}`}
                                                                key={index}>

                                                            </div>}
                                                        {task.status === 'Completed' &&
                                                            <div
                                                                className={`${style.marker} ${style.completedMaker} ${style[`marker-${index + 1}`]}`}
                                                                key={index}>
                                                                <div className="mkr-g"></div>
                                                            </div>}
                                                        {task.status !== 'Completed' && task.status !== 'In Progress' &&
                                                            <div
                                                                className={`${style.marker} ${style.upcomingMarker} ${style[`marker-${index + 1}`]}`}
                                                                key={index}>
                                                                <div className="mkr-g"></div>
                                                            </div>}
                                                    </Fragment>
                                                )}

                                                {activeEmployee.taskJourney.slice(0, 6).map((task, index) =>
                                                    <div className={`${style.tm1} ${style[`timer-${index + 1}`]}`} key={index}>
                                                        {task.time}
                                                    </div>
                                                )}

                                                {activeEmployee.taskJourney.slice(0, 6).map((task, index) =>
                                                    <div className={`${style[`tcom-${index + 1}`]} ${style[`tcom-box`]}`} key={index}>
                                                        {task.status === 'Completed' &&
                                                            <div className={`${style.tcom}`}>{task.status}</div>}
                                                        {task.status === 'In Progress' &&
                                                            <div className={`${style.tpro}`}>{task.status}</div>}
                                                        {task.status !== 'Completed' && task.status !== 'In Progress' &&
                                                            <div className={`${style.tpen}`}>{task.status}</div>}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}


export default EmployeeDetails
