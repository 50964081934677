export function GAI({className}) {
    return (
        <svg width="48" height="14" viewBox="0 0 48 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M7.16881 10.9222C7.16881 11.6515 6.96531 12.2515 6.55833 12.722C6.16297 13.1808 5.62807 13.516 4.95363 13.7278C4.27919 13.9395 3.52336 14.0277 2.68612 13.9924C1.86052 13.9689 1.01747 13.8336 0.156981 13.5866C0.156981 13.1396 0.156981 12.6808 0.156981 12.2103C0.156981 11.7398 0.156981 11.281 0.156981 10.834C0.645367 11.0575 1.13375 11.2339 1.62214 11.3633C2.11053 11.481 2.5524 11.5516 2.94776 11.5751C3.34312 11.5986 3.6629 11.5692 3.90709 11.4869C4.15128 11.3927 4.27338 11.2398 4.27338 11.0281C4.27338 10.8634 4.16872 10.734 3.95941 10.6399C3.76173 10.5458 3.5001 10.4634 3.17451 10.3929C2.84892 10.3105 2.50007 10.2164 2.12797 10.1106C1.76749 9.99292 1.42446 9.83411 1.09887 9.63414C0.773278 9.4224 0.505828 9.14596 0.29652 8.80483C0.09884 8.45193 0 7.99904 0 7.44617C0 6.78742 0.186052 6.22866 0.558155 5.7699C0.941887 5.29936 1.45353 4.94647 2.09308 4.7112C2.73264 4.46417 3.44777 4.34065 4.23849 4.34065C5.02921 4.34065 5.83738 4.47593 6.66298 4.74649C6.66298 5.19349 6.66298 5.65226 6.66298 6.12279C6.66298 6.59333 6.66298 7.05209 6.66298 7.4991C6.0118 7.18149 5.40132 6.96975 4.83153 6.86388C4.26175 6.75801 3.80243 6.74625 3.45359 6.82859C3.10474 6.89917 2.93032 7.05798 2.93032 7.30501C2.93032 7.46969 3.02916 7.59909 3.22684 7.69319C3.43614 7.77554 3.69778 7.852 4.01174 7.92258C4.33733 7.99316 4.68037 8.08726 5.04084 8.2049C5.41294 8.32253 5.75598 8.48722 6.06994 8.69896C6.39553 8.89893 6.65717 9.18125 6.85485 9.54591C7.06416 9.89881 7.16881 10.3576 7.16881 10.9222Z"
                fill="url(#paint0_linear_888_7337)"/>
            <path
                d="M10.0923 3.58192C9.75509 3.58192 9.44694 3.49958 9.16787 3.33489C8.90042 3.17021 8.68529 2.95259 8.5225 2.68203C8.3597 2.41148 8.27831 2.11151 8.27831 1.78214C8.27831 1.45277 8.3597 1.15868 8.5225 0.899892C8.68529 0.629336 8.90042 0.411715 9.16787 0.247029C9.44694 0.082343 9.75509 0 10.0923 0C10.4179 0 10.7144 0.082343 10.9819 0.247029C11.2609 0.411715 11.4819 0.629336 11.6447 0.899892C11.8075 1.15868 11.8889 1.45277 11.8889 1.78214C11.8889 2.11151 11.8075 2.41148 11.6447 2.68203C11.4819 2.95259 11.2609 3.17021 10.9819 3.33489C10.7144 3.49958 10.4179 3.58192 10.0923 3.58192ZM8.6446 13.7454C8.6446 12.2397 8.6446 10.7222 8.6446 9.19301C8.6446 7.65202 8.6446 6.12868 8.6446 4.62297C9.12135 4.62297 9.60393 4.62297 10.0923 4.62297C10.5807 4.62297 11.0575 4.62297 11.5226 4.62297C11.5226 6.12868 11.5226 7.65202 11.5226 9.19301C11.5226 10.7222 11.5226 12.2397 11.5226 13.7454C11.0575 13.7454 10.5807 13.7454 10.0923 13.7454C9.60393 13.7454 9.12135 13.7454 8.6446 13.7454Z"
                fill="url(#paint1_linear_888_7337)"/>
            <path
                d="M14.4731 13.7454C14.4731 12.6632 14.4731 11.5751 14.4731 10.4811C14.4731 9.38711 14.4731 8.30488 14.4731 7.23443C14.2405 7.23443 14.0021 7.23443 13.7579 7.23443C13.5254 7.23443 13.2928 7.23443 13.0602 7.23443C13.0602 6.79918 13.0602 6.36394 13.0602 5.9287C13.0602 5.49346 13.0602 5.05822 13.0602 4.62297C13.2928 4.62297 13.5254 4.62297 13.7579 4.62297C14.0021 4.62297 14.2405 4.62297 14.4731 4.62297C14.4731 4.21126 14.4731 3.80543 14.4731 3.40547C14.4731 2.99376 14.4731 2.58792 14.4731 2.18797C14.9498 2.18797 15.4324 2.18797 15.9208 2.18797C16.4092 2.18797 16.8859 2.18797 17.3511 2.18797C17.3511 2.58792 17.3511 2.99376 17.3511 3.40547C17.3511 3.80543 17.3511 4.21126 17.3511 4.62297C17.6185 4.62297 17.8918 4.62297 18.1708 4.62297C18.4499 4.62297 18.7232 4.62297 18.9906 4.62297C18.9906 5.05822 18.9906 5.49346 18.9906 5.9287C18.9906 6.36394 18.9906 6.79918 18.9906 7.23443C18.7232 7.23443 18.4499 7.23443 18.1708 7.23443C17.8918 7.23443 17.6185 7.23443 17.3511 7.23443C17.3511 8.30488 17.3511 9.38711 17.3511 10.4811C17.3511 11.5751 17.3511 12.6632 17.3511 13.7454C16.8859 13.7454 16.4092 13.7454 15.9208 13.7454C15.4324 13.7454 14.9498 13.7454 14.4731 13.7454Z"
                fill="url(#paint2_linear_888_7337)"/>
            <path
                d="M28.0553 13.3219C27.253 13.6631 26.4565 13.8748 25.6657 13.9571C24.875 14.0395 24.125 13.9924 23.4157 13.816C22.718 13.6513 22.0959 13.3631 21.5494 12.9514C21.0028 12.5397 20.5726 12.0162 20.2586 11.381C19.9563 10.7458 19.8051 10.0106 19.8051 9.17537C19.8051 8.48133 19.9156 7.84612 20.1365 7.26972C20.3691 6.68155 20.6947 6.16985 21.1133 5.73461C21.5319 5.2876 22.0261 4.94647 22.5959 4.7112C23.1657 4.46417 23.7878 4.34065 24.4622 4.34065C25.0669 4.34065 25.6134 4.44064 26.1018 4.64062C26.5902 4.8406 27.0146 5.12291 27.3751 5.48758C27.7356 5.84048 28.0263 6.26395 28.2472 6.75801C28.4682 7.25207 28.6077 7.79318 28.6658 8.38135C28.724 8.96951 28.6891 9.59297 28.5612 10.2517C27.6077 10.2517 26.6483 10.2517 25.6832 10.2517C24.718 10.2517 23.7587 10.2517 22.8052 10.2517C23.0261 10.6987 23.3924 11.0163 23.9041 11.2045C24.4273 11.3927 25.0436 11.4516 25.753 11.381C26.4739 11.3104 27.2414 11.1222 28.0553 10.8163C28.0553 11.2281 28.0553 11.6457 28.0553 12.0691C28.0553 12.4926 28.0553 12.9102 28.0553 13.3219ZM24.3925 6.95211C24.0552 6.95211 23.7413 7.04621 23.4506 7.23443C23.1715 7.42264 22.9564 7.71084 22.8052 8.09903C23.3285 8.09903 23.8517 8.09903 24.375 8.09903C24.9099 8.09903 25.439 8.09903 25.9623 8.09903C25.8227 7.69908 25.6134 7.41088 25.3343 7.23443C25.0669 7.04621 24.7529 6.95211 24.3925 6.95211Z"
                fill="url(#paint3_linear_888_7337)"/>
            <path
                d="M39.8698 13.7454C39.8698 13.6513 39.8698 13.5631 39.8698 13.4807C39.8698 13.3866 39.8698 13.2984 39.8698 13.2161C39.5791 13.4631 39.2477 13.6513 38.8756 13.7807C38.5151 13.9219 38.1256 13.9924 37.707 13.9924C37.1139 13.9924 36.5558 13.8807 36.0325 13.6572C35.5092 13.4219 35.0441 13.0925 34.6371 12.6691C34.2418 12.2338 33.9278 11.7221 33.6952 11.134C33.4743 10.5458 33.3638 9.89293 33.3638 9.17537C33.3638 8.44604 33.4743 7.7873 33.6952 7.19914C33.9278 6.61097 34.2418 6.11103 34.6371 5.69932C35.0441 5.27584 35.5092 4.95235 36.0325 4.72884C36.5558 4.49358 37.1081 4.37595 37.6895 4.37595C38.1081 4.37595 38.5035 4.44653 38.8756 4.58768C39.2477 4.71708 39.5791 4.89941 39.8698 5.13468C39.8698 5.04057 39.8698 4.95235 39.8698 4.87C39.8698 4.78766 39.8698 4.70532 39.8698 4.62297C40.3466 4.62297 40.8292 4.62297 41.3175 4.62297C41.8059 4.62297 42.2827 4.62297 42.7478 4.62297C42.7478 6.12868 42.7478 7.65202 42.7478 9.19301C42.7478 10.7222 42.7478 12.2397 42.7478 13.7454C42.2827 13.7454 41.8059 13.7454 41.3175 13.7454C40.8292 13.7454 40.3466 13.7454 39.8698 13.7454ZM38.0907 11.4163C38.6489 11.4163 39.0965 11.2104 39.4338 10.7987C39.771 10.387 39.9396 9.84588 39.9396 9.17537C39.9396 8.50486 39.771 7.96963 39.4338 7.56968C39.0965 7.15796 38.6489 6.95211 38.0907 6.95211C37.5209 6.95211 37.0674 7.15208 36.7302 7.55203C36.4046 7.95199 36.2418 8.4931 36.2418 9.17537C36.2418 9.85764 36.4046 10.4046 36.7302 10.8163C37.0674 11.2163 37.5209 11.4163 38.0907 11.4163Z"
                fill="url(#paint4_linear_888_7337)"/>
            <path
                d="M46.2034 3.58192C45.8662 3.58192 45.5581 3.49958 45.279 3.33489C45.0115 3.17021 44.7964 2.95259 44.6336 2.68203C44.4708 2.41148 44.3894 2.11151 44.3894 1.78214C44.3894 1.45277 44.4708 1.15868 44.6336 0.899892C44.7964 0.629336 45.0115 0.411715 45.279 0.247029C45.5581 0.082343 45.8662 0 46.2034 0C46.529 0 46.8256 0.082343 47.093 0.247029C47.3721 0.411715 47.593 0.629336 47.7558 0.899892C47.9186 1.15868 48 1.45277 48 1.78214C48 2.11151 47.9186 2.41148 47.7558 2.68203C47.593 2.95259 47.3721 3.17021 47.093 3.33489C46.8256 3.49958 46.529 3.58192 46.2034 3.58192ZM44.7557 13.7454C44.7557 12.2397 44.7557 10.7222 44.7557 9.19301C44.7557 7.65202 44.7557 6.12868 44.7557 4.62297C45.2325 4.62297 45.7151 4.62297 46.2034 4.62297C46.6918 4.62297 47.1686 4.62297 47.6337 4.62297C47.6337 6.12868 47.6337 7.65202 47.6337 9.19301C47.6337 10.7222 47.6337 12.2397 47.6337 13.7454C47.1686 13.7454 46.6918 13.7454 46.2034 13.7454C45.7151 13.7454 45.2325 13.7454 44.7557 13.7454Z"
                fill="url(#paint5_linear_888_7337)"/>
            <defs>
                <linearGradient id="paint0_linear_888_7337" x1="-0.91738" y1="-0.56329" x2="55.3048" y2="13.7027"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#7AD9A8"/>
                    <stop offset="1" stopColor="#A7D147"/>
                </linearGradient>
                <linearGradient id="paint1_linear_888_7337" x1="-0.91738" y1="-0.56329" x2="55.3048" y2="13.7027"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#7AD9A8"/>
                    <stop offset="1" stopColor="#A7D147"/>
                </linearGradient>
                <linearGradient id="paint2_linear_888_7337" x1="-0.91738" y1="-0.56329" x2="55.3048" y2="13.7027"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#7AD9A8"/>
                    <stop offset="1" stopColor="#A7D147"/>
                </linearGradient>
                <linearGradient id="paint3_linear_888_7337" x1="-0.91738" y1="-0.56329" x2="55.3048" y2="13.7027"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#7AD9A8"/>
                    <stop offset="1" stopColor="#A7D147"/>
                </linearGradient>
                <linearGradient id="paint4_linear_888_7337" x1="-0.91738" y1="-0.56329" x2="55.3048" y2="13.7027"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#7AD9A8"/>
                    <stop offset="1" stopColor="#A7D147"/>
                </linearGradient>
                <linearGradient id="paint5_linear_888_7337" x1="-0.91738" y1="-0.56329" x2="55.3048" y2="13.7027"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#7AD9A8"/>
                    <stop offset="1" stopColor="#A7D147"/>
                </linearGradient>
            </defs>
        </svg>

    )
}