import { configureStore } from '@reduxjs/toolkit';
import chatReducer from "./chatTimeline";
import allDataReducer from "./allData";

const store = configureStore({
    reducer: {
        chats: chatReducer,
        allData: allDataReducer,
    },
});

export default store;