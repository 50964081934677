import { createSlice } from "@reduxjs/toolkit";

const chatSlice = createSlice({
    name: "chats",
    initialState: [],
    reducers: {
        addChat: (state, action) => {
            const newChat = {
                // id: Math.random(),
                from: action.payload.from,
                msg: JSON.stringify(action.payload.msg),
                component_name: action.payload.component_name,
                meta: action.payload.meta,
                // date: Date.now(),
            };
            state.push(newChat);
        },
        reset: (state) => {
            state.length = 0;
        },
    },
});
export const { addChat,clearChat,reset} = chatSlice.actions;
export default chatSlice.reducer;