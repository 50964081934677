import {FilterType} from "../components/Analytics/WorkerAnalyticsChart";

export function getRandomNumbers(length, min, max) {
    const randomNumbers = [];

    // Loop to generate the specified number of random numbers
    for (let i = 0; i < length; i++) {
        // Generate a random number between min and max (inclusive)
        const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
        randomNumbers.push(randomNum);
    }

    return randomNumbers;
}

export function getLast7Hours() {
    const hoursArray = [];

    // Get the current date and time
    const currentDate = new Date();

    // Function to format the time as hh:mm AM/PM
    function formatAMPM(date) {
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'

        // Format minutes to always be two digits
        // eslint-disable-next-line no-useless-concat
        const strMinutes = minutes < 10 ? '' + '' : '';

        // Return formatted time string
        return `${hours}${strMinutes} ${ampm}`;
    }

    // Loop through the last 7 hours
    for (let i = 6; i >= 0; i--) {
        // Create a new Date object for each hour, subtracting 'i' hours from the current date
        const hourDate = new Date(currentDate);
        hourDate.setHours(currentDate.getHours() - i, 0, 0, 0); // Set hours and reset minutes, seconds, milliseconds

        // Format the hour in AM/PM format and push it into the array
        const formattedHour = formatAMPM(hourDate);
        hoursArray.push(formattedHour);
    }

    return hoursArray;
}

export function last7Days() {
    const dates = [];
    const today = new Date();

    for (let i = 1; i <= 7; i++) {
        const previousDate = new Date(today);
        previousDate.setDate(today.getDate() - i);
        const day = previousDate.getDate().toString().padStart(2, '0'); // Add leading zero if needed
        const month = (previousDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
        dates.push(`${day}/${month}`); // Format as MM-DD
    }

    return dates.reverse();
}

export function getLast7Months() {
    const monthsArray = [];

    // Get the current date
    const currentDate = new Date();

    // Loop through the last 7 months
    for (let i = 6; i >= 0; i--) {
        // Create a new Date object for each month, subtracting 'i' months from the current date
        const monthDate = new Date(currentDate);
        monthDate.setMonth(currentDate.getMonth() - i);

        // Format the month as a short name (e.g., 'Nov', 'Oct')
        const shortMonthName = monthDate.toLocaleString('default', { month: 'short' });

        // Push the short name of the month to the array
        monthsArray.push(shortMonthName);
    }

    return monthsArray;
}

export function getLast7Years() {
    const yearsArray = [];

    // Get the current date
    const currentDate = new Date();

    // Loop through the last 7 months
    for (let i = 6; i >= 0; i--) {
        // Create a new Date object for each month, subtracting 'i' months from the current date
        const yearDate = new Date(currentDate);
        yearDate.setFullYear(currentDate.getFullYear() - i);

        // Format the month as a short name (e.g., 'Nov', 'Oct')
        const shortYearName = yearDate.toLocaleString('default', { year: 'numeric' });

        // Push the short name of the month to the array
        yearsArray.push(shortYearName);
    }

    return yearsArray;
}

export function getRandomArbitrary(min, max) {
    return Array.from({length: 6}, () => Math.floor(Math.random() * 40));
}


export function convertToMinutes(timeString) {
    // Use regular expressions to handle different cases:
    // 1. Hours and minutes ("X hrs Y mins")
    // 2. Only hours ("X hrs")
    // 3. Only minutes ("Y mins")
    const regex = /(\d+)\s*hrs?\s*(\d*)\s*mins?|(\d+)\s*mins?|(\d+)\s*hrs?|(\d+)\s*hr?/;

    const match = timeString.match(regex);
    if (match) {
        // Case 1: Both hours and minutes (e.g., "3 hrs 15 mins")
        if (match[1]) {
            const hours = parseInt(match[1]);
            const minutes = match[2] ? parseInt(match[2]) : 0; // Default to 0 minutes if not provided
            return (hours * 60) + minutes;
        }

        // Case 2: Only hours (e.g., "3 hrs")
        if (match[4] && (match[0].includes('hrs') || match[0].includes('hr')) ) {
            const hours = parseInt(match[4]);
            return hours * 60;
        }

        // Case 3: Only minutes (e.g., "15 mins")
        if (match[3]) {
            return parseInt(match[3]);
        }
    } else {
        throw new Error("Invalid time format. Please use the format 'X hrs Y mins', 'X hrs', or 'Y mins'");
    }
}

export const handleXAxisData = (filterType) => {
    if (filterType === FilterType.DAY.toString()) {
        return getLast7Hours();
    }
    if (filterType === FilterType.WEEK.toString()) {
        return last7Days();
    }
    if (filterType === FilterType.MONTH.toString()) {
        return getLast7Months();
    }
    if (filterType === FilterType.YEAR.toString()) {
        return getLast7Years();
    }
}
export const handleYAxisData = (filterType) => {
    if (filterType === FilterType.DAY.toString()) {
        return getRandomNumbers(7,0,9);
    }
    if (filterType === FilterType.WEEK.toString()) {
        return getRandomNumbers(7,0,9);
    }
    if (filterType === FilterType.MONTH.toString()) {
        return getRandomNumbers(7,0,9)
    }
    if (filterType === FilterType.YEAR.toString()) {
        return getRandomNumbers(7,0,9)
    }
}


