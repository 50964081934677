// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LeftMenu_disabledIcon__cH3rt > a {
    cursor: auto;
}

.LeftMenu_disabledIcon__cH3rt > a > img {
    cursor: auto;
}

.LeftMenu_enabledIcon__2pW4t > a {
    cursor: pointer;
}

.LeftMenu_enabledIcon__2pW4t > a > img {
    cursor: pointer;
}

.LeftMenu_enabledIcon__2pW4t img {
    cursor: pointer;
}

.LeftMenu_enabledIcon__2pW4t > a > svg {
    cursor: pointer;
}

.LeftMenu_enabledIcon__2pW4t svg {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/LeftMenu.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".disabledIcon > a {\n    cursor: auto;\n}\n\n.disabledIcon > a > img {\n    cursor: auto;\n}\n\n.enabledIcon > a {\n    cursor: pointer;\n}\n\n.enabledIcon > a > img {\n    cursor: pointer;\n}\n\n.enabledIcon img {\n    cursor: pointer;\n}\n\n.enabledIcon > a > svg {\n    cursor: pointer;\n}\n\n.enabledIcon svg {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disabledIcon": `LeftMenu_disabledIcon__cH3rt`,
	"enabledIcon": `LeftMenu_enabledIcon__2pW4t`
};
export default ___CSS_LOADER_EXPORT___;
