import React, {useEffect} from 'react'
import "./AlertFloorMap.css"
import flrmap from "../images/floor-map.png"
import IncidentCard from './incident/IncidentCard';


function AlertFloorMap() {

    const alertData = [
        {
            name: 'Missing Helmet',
            id: 1,
            image: 'alert_icon.svg',
            capture_image:'missing-helmet-img.png',
            capture_video:'missing-helmet.mp4',
            cp_image:'sm-cp-img.png',
            loc: { x: '74', y: '196' },
            label: 'Tower 3, Zone 1',
            operator: 'Karen Chilton',
            work_type:'Material Transport',
            alert_type_level:'Safety Infraction | Severity Level - 2 | Cam#DID1',
            alert_date_time: '02-11-2024 | 07:00 AM',
            
        },
        {
            name: 'Unauthorized Visit',
            id: 2,
            image: 'alert_icon.svg',
            capture_image: 'unauthorized-entry-img.png',
            capture_video: 'unauthorized-entry.mp4',
            cp_image:'sm-cp-img.png',
            loc: { x: '-69', y: '275' },
            label: 'Packaging, Zone 1',
            operator: 'Fed Chilton',
            work_type:'Material Transport',
            alert_type_level:'Human Infraction | Severity Level - 3 | Cam#DID2',
            alert_date_time: '02-11-2024 | 08:15 AM',
            

        },
        {
            name: 'Missing Gloves',
            id: 3,
            image: 'alert_icon.svg',
            capture_image: 'missing-safety-vest-img.png',
            capture_video: 'missing-safety-vest.mp4',
            cp_image:'sm-cp-img.png',
            loc: { x: '-127', y: '374' },
            label: 'Construction, Zone 1',
            operator: 'Peter Bishop',
            work_type:'Material Transport',
            alert_type_level:'Safety Infraction | Severity Level - 2 | Cam#DID3',
            alert_date_time: '02-11-2024 | 09:00 AM',
        },
        {
            name: 'Missing Helmet',
            id: 6,
            image: 'alert_icon.svg',
            capture_image:'missing-helmet-img.png',
            capture_video:'missing-helmet.mp4',
            cp_image:'sm-cp-img.png',
            loc: { x: '4', y: '513' },
            label: 'Construction, Zone 1',
            operator: 'Pannola Van',
            work_type:'Material Transport',
            alert_type_level:'Safety Infraction | Severity Level - 2 | Cam#DID9',
            alert_date_time: '02-11-2024 | 08:00 PM',
        },
        {
            name: 'Unauthorized Visit',
            id: 8,
            image: 'alert_icon.svg',
            capture_image: 'missing-safety-vest-img.png',
            capture_video: 'missing-safety-vest.mp4',
            cp_image:'sm-cp-img.png',
            loc: { x: '96', y: '581' },
            label: 'Tower 2, Zone 3',
            operator: 'Wesly Tim',
            work_type:'Material Transport',
            alert_type_level:'Human Infraction | Severity Level - 3 | Cam#DID2',
            alert_date_time: '02-11-2024 | 09:25 PM',

        },
        {
            name: 'Missing Helmet',
            id: 9,
            image: 'alert_icon.svg',
            capture_image:'missing-helmet-img.png',
            capture_video:'missing-helmet.mp4',
            cp_image:'sm-cp-img.png',
            loc: { x: '-176', y: '771' },
            label: 'Packaging, Zone 1',
            operator: 'Jack Fred',
            work_type:'Material Transport',
            alert_type_level:'Safety Infraction | Severity Level - 2 | Cam#DID3',
            alert_date_time: '02-11-2024 | 09:35 PM',
        },
    ];
    const [showIncidentCard, setShowIncidentCard] = React.useState(false);

    const [currentIncidentXPostion, setCurrentIncidentXPostion] = React.useState(0);
    const [currentIncidentYPostion, setCurrentIncidentYPostion] = React.useState(0);
    const [activeIncident, setActiveIncident] = React.useState();


    const currentIncidentPostion = (e) => {
        setCurrentIncidentXPostion(e.pageX);
        setCurrentIncidentYPostion(e.pageY);
    }

    const handleIncidentCard = (e,incident) => {
        currentIncidentPostion(e);
        // eslint-disable-next-line eqeqeq
        if (activeIncident?.id == incident?.id && showIncidentCard) {
            setShowIncidentCard(false);
        } else {
            setShowIncidentCard(true);
            setActiveIncident(incident);
        }
    }

    useEffect(() => {
        const el = document.getElementsByClassName('AlertFloorMap')[0];
        const observer = new window.IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                return
            }
            setShowIncidentCard(false);
        }, {
            root: null,
            threshold: 0.1, // set offset 0.1 means trigger if atleast 10% of element in viewport
        })

        observer.observe(el);

    }, []);

    return (
        <>
            {activeIncident && showIncidentCard && <IncidentCard x={currentIncidentXPostion} y={currentIncidentYPostion} activeIncident={activeIncident} handleParentClose={handleIncidentCard} />}
            <div className='floor-container AlertFloorMap'>

                <div className="floor-map">
                    <div className="workers-alert">
                        {alertData.map((incident, key) =>
                            <img src={require(`../images/${incident.image}`)}
                                 alt=""
                                 className='wk-alert'
                                 key={key}
                                 style={{top: incident.loc.x + 'px', left: incident.loc.y + 'px'}}
                                 onClick={(e) => handleIncidentCard(e, incident)}
                            />
                        )}
                    </div>


                    <div className="inner-floor">
                        <img src={flrmap} alt="" />
                    </div>

                </div>
            </div>
        </>
    )
}

export default AlertFloorMap
