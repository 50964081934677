// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

.floor-container {

    display: flex;
    width: 100%;
    padding: 20px 25px;
    background-color: #1a1a1a;
    flex-direction: column;
}

.floor-map {
    max-width: 1241px;
    height: 563px;
    background: #1E2223;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    margin-top: 15px;

}

.inner-floor {
    position: relative;
}

.workers-alert {
    position: relative;
}

img.wk-alert {
    cursor: pointer;
    z-index: 98;
    position: absolute;
    right: 18rem;
    bottom: -1rem;
}

img.wk-alert:hover {
    cursor: pointer;
    z-index: 98;
    position: absolute;
    right: 18rem;
    bottom: -1rem;
    filter: drop-shadow(4px 4px 4px #a13232);
    transform: scale(1.5);
}`, "",{"version":3,"sources":["webpack://./src/components/AlertFloorMap.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;;AAE1B;;AAEA;;IAEI,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;;AAEpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,wCAAwC;IACxC,qBAAqB;AACzB","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n\n}\n\n.floor-container {\n\n    display: flex;\n    width: 100%;\n    padding: 20px 25px;\n    background-color: #1a1a1a;\n    flex-direction: column;\n}\n\n.floor-map {\n    max-width: 1241px;\n    height: 563px;\n    background: #1E2223;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-left: 16px;\n    margin-top: 15px;\n\n}\n\n.inner-floor {\n    position: relative;\n}\n\n.workers-alert {\n    position: relative;\n}\n\nimg.wk-alert {\n    cursor: pointer;\n    z-index: 98;\n    position: absolute;\n    right: 18rem;\n    bottom: -1rem;\n}\n\nimg.wk-alert:hover {\n    cursor: pointer;\n    z-index: 98;\n    position: absolute;\n    right: 18rem;\n    bottom: -1rem;\n    filter: drop-shadow(4px 4px 4px #a13232);\n    transform: scale(1.5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
