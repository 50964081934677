import React, {Fragment} from 'react'
import "./EquipmentCard.css"
import EquipmentDetails from "./EquipmentDetails"
import infocap from "../../images/info-cap.svg"
import team1 from "../../images/teams-cap.svg"
import lz from "../../images/location-logo.png"
import lg1 from "../../images/fitness-logo.svg"
import lg2 from "../../images/trustbd.svg"
import lg5 from "../../images/work-progress.svg"
import mtype from "../../images/todo.svg"
import user from "../../images/inciuser.svg"
import { PieChart } from '@mui/x-charts/PieChart';
import {getLatLong} from "../../util/latLong";


function EquipmentCard({ x, y, activeEquipment,handleParentCardClose }) {

    const [showEmployeeDetail, setShowEmployeeDetail] = React.useState(false);
    const handleInfoIconClick = (e) => {
        //todo
        setShowEmployeeDetail(true);
    }
    const handleDetailCardClose = () => {
        handleParentCardClose(true);
        setShowEmployeeDetail(false);
    }
    return (
        <>
            {showEmployeeDetail && <EquipmentDetails activeEquipment={activeEquipment} handleDetailCardClose={handleDetailCardClose} />}
            <div className='equip-card-container'>
                <div className='equip-worker-card' style={{
                    zIndex: '997',
                    position: 'absolute',
                    top: (parseInt(getLatLong(activeEquipment.equipment_location ?? activeEquipment.currentStatus.location).num1) + 70) + 'px',
                    left: (parseInt(getLatLong(activeEquipment.equipment_location ?? activeEquipment.currentStatus.location).num2) + 20) + 'px'
                }}>
                    <div className='equip-wc-container'>
                        <div className='wc-image'>
                            {/* <img src={equipmentImage} alt="" style={{maxWidth:'65px'}} /> */}
                             <img src={require(`../../images/${activeEquipment.image_raw}`)} alt="" style={{width:'65px', borderRadius:'50%'}} />
                        </div>
                        <div className='equip-wc-wrapper-2'>
                            <div className='equip-det-1'>
                                <div className="ec-head"> <p className='equip-w-name'>{activeEquipment.equipment_name ?? activeEquipment.equipmentName}</p></div>
                                <div className="ec-info-logo"><img src={infocap} alt="" className="pointer" onClick={handleInfoIconClick} /></div>

                            </div>
                            <div className='equip-det-2'>
                                <p className='equip-w-id'
                                   style={{textAlign: "left"}}>{activeEquipment.equipment_id ?? activeEquipment.equipmentId}</p>
                                <p className='equip-w-id' style={{textAlign:"left"}}>{activeEquipment.task_id ?? activeEquipment.taskId}</p>
                            </div>

                            <div className='equip-det-3'>
                                <div className='w-1'><p className='equip-work-a' style={{textAlign:"left"}}><img src={user} alt="" /> {activeEquipment.assigned_operator_name ?? activeEquipment.assignedOperator.name}</p></div>
                                <div className='w-2'><p className='equip-work-a' style={{textAlign:"left"}}><img src={mtype} alt="" /> {activeEquipment.task_name ?? activeEquipment.currentStatus.task}</p></div>
                            </div>
                            <div className='equip-det-4'>
                                <div className='w-1'><p className='equip-work-a' style={{textAlign:"left"}}><img src={team1} alt="" /> {activeEquipment.worker_team ?? activeEquipment.currentStatus.team}</p></div>
                                <div className='w-2'><p className='equip-work-a' style={{textAlign:"left"}}><img src={lz} alt="" style={{objectFit:'contain',height: 'auto'}} /> {activeEquipment.equipment_location_title ?? activeEquipment.currentStatus.location}</p></div>
                            </div>
                        </div>
                    </div>

                    <div className='btm-wrapper'>
                        <div className='btm-sec1'>
                            <div className="tp1">
                                <img src={lg1} alt="" style={{paddingTop:"2px"}}/>
                                <div className="c-tooltip tpup ">Health Status - Good</div>
                            </div>
                            <div className="tp1">
                                <img src={lg2} alt="" style={{paddingTop:"1px"}}/>
                                <div className="c-tooltip tpup ">Safety Violation - No Violation</div>
                            </div>
                            <div className="tp1">
                                <p className='p-ninety-sm'>{activeEquipment?.scores?.productivityScore}</p>
                                <div className="c-tooltip tpup ">Safety Score - Good</div>
                            </div>

                            <div className="progress tp1">
                                <p className='t-p'>5/{activeEquipment?.equipment_completed_tasks ?? activeEquipment.taskProgress.completedTrips}</p>

                                <PieChart
                                    series={[
                                        {
                                            data: [
                                                {id: 0, value: 50, color: '#b3cf2d'},
                                                {id: 1, value: 50, color: '#959595'},

                                            ],
                                            innerRadius: 13, outerRadius: 16, cx: 15, cy: 22,

                                        },
                                    ]}
                                    width={40}
                                    height={55}

                                />
                                <div className="c-tooltip-c tpup-c ">Completed /Assigned</div>
                            </div>

                            <div className="tp1">
                                <img src={lg5} alt="" style={{paddingTop:"1px"}}/>
                                <div className="c-tooltip tpup ">Work Status - Idle</div>
                            </div>

                            <div className="tp1">
                                <p className='p-ninety-sm'>{activeEquipment?.scores?.safetyScore}</p>
                                <div className="c-tooltip-n tpup-n ">Productivity Score - Good</div>
                            </div>

                        </div>
                            <div className="bar-parent-sm">
                                <div className="bar-wrap-sm">
                                    {/*{activeEquipment?.taskJourney.map((data, index) =>*/}
                                    {/*    <Fragment key={index}>*/}
                                    {/*        {data.status === 'Completed' && <div className={`ct-1-sm`}></div>}*/}
                                    {/*        {data.status === 'In Progress' && <div className={`ct-2-sm`}></div>}*/}
                                    {/*        {data.status === 'Upcoming' && <div className={`ct-3-sm`}></div>}*/}
                                    {/*    </Fragment>*/}
                                    {/*)}*/}
                                    {/*{activeEquipment?.taskJourney.map((data, index) =>*/}
                                    {/*    <Fragment key={index}>*/}
                                    {/*        <div className={`marker-${index + 1}-sm`}>*/}
                                    {/*            <div className="mkr-g-sm"></div>*/}
                                    {/*        </div>*/}
                                    {/*    </Fragment>*/}
                                    {/*)}*/}


                                {/*<div className="bar-alert-sm"></div>*/}
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default EquipmentCard
