import React from 'react'
import "./SafetyInfractions.css"
// import cblogo from "../../images/cb-logo.svg"
import infocap from "../../images/info-logo.png"
import mtype from "../../images/todo.svg"
import lz from "../../images/location-logo.png"
import user from "../../images/inciuser.svg"
import timeline from "../../images/inctimline.svg"
import IncidentDetails from './IncidentDetails'

function SafetyInfractions() {
    const alertData = [
    {
        name: 'Missing Helmet',
        id: 1,
        image: 'alert_icon.svg',
        capture_image: 'missing-helmet-img.png',
        capture_video: 'missing-helmet.mp4',
        cp_image:'sm-cp-img.png',
        loc: { x: '74', y: '-280' },
        label: 'Tower 3, Zone 1',
        operator: 'Karen Chilton',
        work_type: 'Material Transport',
        alert_type_level: 'Safety Infraction | Severity Level - 2 | Cam#DID1',
        alert_date_time: '02-11-2024 | 09:00 AM',

    }, {
        name: 'Unauthorized Visit',
        id: 2,
        image: 'alert_icon.svg',
        capture_image: 'unauthorized-entry-img.png',
        capture_video: 'unauthorized-entry.mp4',
        cp_image:'sm-cp-img.png',
        loc: { x: '-69', y: '-186' },
        label: 'Packaging, Zone 1',
        operator: 'Fed Chilton',
        work_type: 'Material Transport',
        alert_type_level: 'Human Infraction | Severity Level - 3 | Cam#DID2',
        alert_date_time: '01-11-2024 | 03:00 PM',
    },
        {
        name: 'Unauthorized Visit',
        id: 8,
        image: 'alert_icon.svg',
        capture_image: 'unauthorized-entry-img.png',
        capture_video: 'unauthorized-entry.mp4',
        cp_image:'sm-cp-img.png',
        loc: { x: '96', y: '120' },
        label: 'Tower 2, Zone 3',
        operator: 'Wesly Tim',
        work_type: 'Material Transport',
        alert_type_level: 'Human Infraction | Severity Level - 3 | Cam#DID2',
        alert_date_time: '01-11-2024 | 09:25 PM',

    }, {
        name: 'Missing Helmet',
        id: 9,
        image: 'alert_icon.svg',
        capture_image: 'missing-helmet-img.png',
        capture_video: 'missing-helmet.mp4',
        cp_image:'sm-cp-img.png',
        loc: { x: '-176', y: '309' },
        label: 'Packaging, Zone 1',
        operator: 'Jack Fred',
        work_type: 'Material Transport',
        alert_type_level: 'Safety Infraction | Severity Level - 2 | Cam#DID3',
        alert_date_time: '30-10-2024 | 09:35 PM',
    }, {
        name: 'Missing Safety Goggles',
        id: 10,
        image: 'alert_icon.svg',
        capture_image: 'missing-goggles-img.png',
        capture_video: 'missing-goggles.mp4',
        cp_image:'sm-cp-img.png',
        loc: { x: '120', y: '-150' },
        label: 'Assembly Line, Zone 4',
        operator: 'Lana Gordon',
        work_type: 'Machinery Operation',
        alert_type_level: 'Safety Infraction | Severity Level - 2 | Cam#DID4',
        alert_date_time: '02-11-2024 | 10:00 AM',
    }, {
        name: 'Missing Safety Vest',
        id: 11,
        image: 'alert_icon.svg',
        capture_image: 'missing-safety-vest-img.png',
        capture_video: 'missing-safety-vest.mp4',
        cp_image:'sm-cp-img.png',
        loc: { x: '50', y: '-70' },
        label: 'Warehouse, Zone 3',
        operator: 'Joe Chill',
        work_type: 'Loading Operations',
        alert_type_level: 'Safety Infraction | Severity Level - 2 | Cam#DID5',
        alert_date_time: '02-11-2024 | 02:30 PM',
    }, {
        name: 'Missing Safety Gloves',
        id: 12,
        image: 'alert_icon.svg',
        capture_image: 'missing-helmet-img.png',
        capture_video: 'missing-helmet.mp4',
        cp_image:'sm-cp-img.png',
        loc: { x: '-110', y: '-40' },
        label: 'Packaging, Zone 2',
        operator: 'Amy Jackson',
        work_type: 'Packing',
        alert_type_level: 'Safety Infraction | Severity Level - 2 | Cam#DID6',
        alert_date_time: '02-11-2024 | 11:15 AM',
    }, {
        name: 'Close to Running Machinery',
        id: 13,
        image: 'alert_icon.svg',
        capture_image: 'close-to-machinery-img.png',
        capture_video: 'close-to-machinery.mp4',
        cp_image:'sm-cp-img.png',
        loc: { x: '200', y: '-90' },
        label: 'Machinery Area, Zone 5',
        operator: 'Frank Miller',
        work_type: 'Machine Inspection',
        alert_type_level: 'Proximity Infraction | Severity Level - 1 | Cam#DID7',
        alert_date_time: '01-11-2024 | 12:45 PM',
    },
    //     {
    //     name: 'Human to Vehicle',
    //     id: 14,
    //     image: 'alert_icon.svg',
    //     capture_image: 'human-to-vehicle-img.png',
    //     capture_video: 'human-to-vehicle.mp4',
    //     cp_image:'sm-cp-img.png',
    //     loc: { x: '75', y: '-300' },
    //     label: 'Parking Lot, Zone 2',
    //     operator: 'Henry Cavil',
    //     work_type: 'Vehicle Movement',
    //     alert_type_level: 'Collision Detection | Severity Level - 1 | Cam#DID8',
    //     alert_date_time: '31-10-2024 | 04:15 PM',
    // },
        {
        name: 'Smoking',
        id: 15,
        image: 'alert_icon.svg',
        capture_image: 'low-air-quality-img.png',
        capture_video: 'low-air-quality.mp4',
        cp_image:'sm-cp-img.png',
        loc: { x: '-50', y: '150' },
        label: 'Break Area, Zone 1',
        operator: 'Selina Kyle',
        work_type: 'Break',
        alert_type_level: 'Human Infraction | Severity Level - 3 | Cam#DID9',
        alert_date_time: '02-11-2024 | 05:20 PM',
    }, {
        name: 'Fire',
        id: 16,
        image: 'alert_icon.svg',
        capture_image: 'fire-img.png',
        capture_video: 'fire.mp4',
        cp_image:'sm-cp-img.png',
        loc: { x: '-60', y: '100' },
        label: 'Storage Area, Zone 2',
        operator: 'Robin Grayson',
        work_type: 'Fire Emergency',
        alert_type_level: 'Environmental Infraction | Severity Level - 1 | Cam#DID10',
        alert_date_time: '01-11-2024 | 06:30 PM',
    }, {
        name: 'Poisonous Gas',
        id: 17,
        image: 'alert_icon.svg',
        capture_image: 'poisonous-gas-img.png',
        capture_video: 'poisonous-gas.mp4',
        cp_image:'sm-cp-img.png',
        loc: { x: '0', y: '20' },
        label: 'Chemical Storage, Zone 3',
        operator: 'Robert Pearson',
        work_type: 'Hazardous Materials Handling',
        alert_type_level: 'Environmental Infraction | Severity Level - 1 | Cam#DID11',
        alert_date_time: '01-11-2024 | 08:45 PM',
    },{
        name: 'Missing Safety Shoes',
        id: 18,
        image: 'alert_icon.svg',
        capture_image: 'missing-safety-vest-img.png',
        capture_video: 'missing-safety-vest.mp4',
        cp_image: 'sm-cp-img.png',
        loc: { x: '-100', y: '80' },
        label: 'Workshop Area, Zone 2',
        operator: 'Bruce Wayne',
        work_type: 'Workshop Operations',
        alert_type_level: 'Safety Infraction | Severity Level - 2 | Cam#DID12',
        alert_date_time: '02-11-2024 | 11:30 AM'
    },
    {
        name: 'Close to Moving Vehicle',
        id: 19,
        image: 'alert_icon.svg',
        capture_image: 'human-to-machinery-img.png',
        capture_video: 'human-to-machinery.mp4',
        cp_image: 'sm-cp-img.png',
        loc: { x: '150', y: '-120' },
        label: 'Loading Bay, Zone 1',
        operator: 'Dick Grayson',
        work_type: 'Loading Operations',
        alert_type_level: 'Proximity Infraction | Severity Level - 1 | Cam#DID13',
        alert_date_time: '02-11-2024 | 02:15 PM'
    },
    {
        name: 'Vehicle to Machinery',
        id: 20,
        image: 'alert_icon.svg',
        capture_image: 'vehicle-to-vehicle-img.png',
        capture_video: 'vehicle-to-vehicle.mp4',
        cp_image: 'sm-cp-img.png',
        loc: { x: '180', y: '60' },
        label: 'Machinery Area, Zone 3',
        operator: 'Tim Drake',
        work_type: 'Vehicle Operation',
        alert_type_level: 'Collision Detection | Severity Level - 1 | Cam#DID14',
        alert_date_time: '02-11-2024 | 03:45 PM'
    },
    {
        name: 'Vehicle to Vehicle',
        id: 21,
        image: 'alert_icon.svg',
        capture_image: 'vehicle-to-vehicle-img.png',
        capture_video: 'vehicle-to-vehicle.mp4',
        cp_image: 'sm-cp-img.png',
        loc: { x: '-140', y: '200' },
        label: 'Parking Area, Zone 4',
        operator: 'Jason Todd',
        work_type: 'Vehicle Movement',
        alert_type_level: 'Collision Detection | Severity Level - 1 | Cam#DID15',
        alert_date_time: '02-11-2024 | 04:30 PM'
    },
    {
        name: 'Human to Machinery',
        id: 22,
        image: 'alert_icon.svg',
        capture_image: 'human-to-machinery-img.png',
        capture_video: 'human-to-machinery.mp4',
        cp_image: 'human-to-machinery-img.png',
        loc: { x: '220', y: '-180' },
        label: 'Production Area, Zone 2',
        operator: 'Barbara Gordon',
        work_type: 'Machine Operation',
        alert_type_level: 'Collision Detection | Severity Level - 1 | Cam#DID16',
        alert_date_time: '02-11-2024 | 01:15 PM'
    },
    {
        name: 'Person Collapse',
        id: 23,
        image: 'alert_icon.svg',
        capture_image: 'person-collapse-img.png',
        capture_video: 'person-collapse.mp4',
        cp_image: 'sm-cp-img.png',
        loc: { x: '-90', y: '-220' },
        label: 'Break Room, Zone 1',
        operator: 'Kate Kane',
        work_type: 'Break Time',
        alert_type_level: 'Human Infraction | Severity Level - 3 | Cam#DID17',
        alert_date_time: '02-11-2024 | 02:45 PM'
    },
    // {
    //     name: 'Wrong Gait',
    //     id: 24,
    //     image: 'alert_icon.svg',
    //     capture_image: 'wrong-gait-img.png',
    //     capture_video: 'wrong-gait.mp4',
    //     cp_image: 'sm-cp-img.png',
    //     loc: { x: '160', y: '180' },
    //     label: 'Corridor, Zone 3',
    //     operator: 'Stephanie Brown',
    //     work_type: 'Walking',
    //     alert_type_level: 'Human Infraction | Severity Level - 1 | Cam#DID18',
    //     alert_date_time: '02-11-2024 | 10:30 AM'
    // },
    {
        name: 'Loitering',
        id: 25,
        image: 'alert_icon.svg',
        capture_image: 'low-air-quality-img.png',
        capture_video: 'low-air-quality.mp4',
        cp_image: 'sm-cp-img.png',
        loc: { x: '-120', y: '160' },
        label: 'Restricted Area, Zone 2',
        operator: 'Cassandra Cain',
        work_type: 'Unauthorized Activity',
        alert_type_level: 'Human Infraction | Severity Level - 1 | Cam#DID19',
        alert_date_time: '02-11-2024 | 11:45 AM'
    },
    {
        name: 'Person to Person Fight',
        id: 26,
        image: 'alert_icon.svg',
        capture_image: 'person-to-person-fight-img.png',
        capture_video: 'person-to-person-fight.mp4',
        cp_image: 'sm-cp-img.png',
        loc: { x: '130', y: '-200' },
        label: 'Break Area, Zone 4',
        operator: 'Damian Wayne',
        work_type: 'Break Time',
        alert_type_level: 'Human Infraction | Severity Level - 3 | Cam#DID20',
        alert_date_time: '02-11-2024 | 03:15 PM'
    },
    {
        name: 'Smoke',
        id: 27,
        image: 'alert_icon.svg',
        capture_image: 'low-air-quality-img.png',
        capture_video: 'low-air-quality.mp4',
        cp_image: 'sm-cp-img.png',
        loc: { x: '-80', y: '-160' },
        label: 'Production Area, Zone 1',
        operator: 'Alfred Pennyworth',
        work_type: 'Environmental Monitoring',
        alert_type_level: 'Environmental Infraction | Severity Level - 1 | Cam#DID21',
        alert_date_time: '02-11-2024 | 09:45 AM'
    }    
];

    const [activeIncident, setActiveIncident] = React.useState();


    const [showIncidentDetail, setShowIncidentDetail] = React.useState(false);
    const handleInfoIconClick = (e) => {
        //todo
        setShowIncidentDetail(true);
    }
    const handleDetailCardClose = () => {
        setShowIncidentDetail(false);
    }

    return (<>
        {showIncidentDetail &&
            <IncidentDetails activeIncident={activeIncident} handleDetailCardClose={handleDetailCardClose} />}
        <div className='si-container'>
            {/*<div className='cb-sec'>*/}
            {/*    <p className='cb-para'><img src={cblogo} alt="" /> Here is the yesterday’s safety infractions </p>*/}
            {/*</div>*/}
            <div className="dc-wrapper">
                {alertData.map((alert, key) => <div className="si-sec-1" key={key}>
                    <div className="si-details-wrapper">
                        <div className='si-det-1'>
                            <div className="si-head">
                                <p className='w-name'>{alert.name}</p>
                            </div>
                            <div className="si-info-logo">
                                <img src={infocap} alt="" className="pointer"
                                    onClick={(e) => {
                                        setActiveIncident(alert);
                                        handleInfoIconClick(e)
                                }} />
                            </div>
                            
                        </div>
                        <div className='si-det-2'>
                            <p className='w-id'> {alert.alert_type_level} </p>
                        </div>

                        <div className='si-det-3'>
                            <div className="w1"><p className='work-a'><img src={user} alt="" /> {alert.operator} </p></div>
                            <div className="w2"><p className='work-a'><img src={mtype} alt="" /> {alert.work_type.substring(0, 20)} </p></div>
                            

                        </div>
                        <div className='si-det-4'>
                            <div className="w1"><p className='work-a'><img src={lz} alt="" /> {alert.label.substring(0, 20)} </p></div>
                            <div className="w2"><p className='work-a'><img src={timeline} alt="" /> {alert.alert_date_time} </p></div>
                            
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
    </>)
}

export default SafetyInfractions
