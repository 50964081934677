import group from "../images/Botlogo.png";


const BotHero = ({isLoading}) => {
    return (<div className="bot-icon">
            <img src={group} alt="Bot Icon"/>
        {!isLoading && <p className="instruction-text">
                Welcome to Site AI, What can I do for you</p>}
        </div>)
}

export default BotHero
