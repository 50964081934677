import React, { Fragment, useEffect, useRef } from 'react'
import "./EquipmentDetails.css"
import ctl from "../../images/Ellipse 334.svg"
import ptl from "../../images/Ellipse 332.svg"
import rtl from "../../images/rt-logo.svg"
import fk1 from "../../images/fk-lift.svg"
import sdi1 from "../../images/sdimg-2.svg"
import sdi6 from "../../images/sdimg-7.svg"
import { BarChart } from '@mui/x-charts/BarChart';
import equpt from "../../images/equipteam.svg"
import whours from "../../images/equipwhours.svg"
import eqtrip from "../../images/trips.png"
import equstatus from "../../images/equipstatus.svg"
import maintainance from "../../images/equipmaintenance.svg"
import { PieChart } from '@mui/x-charts/PieChart';
import close from "../../images/close.svg"
import equipmentImage from "../../images/forklift1.svg"
import { last7Days, getRandomArbitrary, convertToMinutes } from '../../util/chartUtil'

import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip';
import style from "../employee/EmployeeDetails.module.css";
// // or
// import { ChartsTooltip } from '@mui/x-charts';
// // or
// import { ChartsTooltip } from '@mui/x-charts-pro';

function EquipmentDetails({ activeEquipment, handleDetailCardClose }) {
    const myRef = useRef(null);
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' });
    useEffect(() => {
        executeScroll();
    }, [activeEquipment]);

    useEffect(() => {
        document.body.classList.add('jw-modal-open');

        return () => document.body.classList.remove('jw-modal-open');
    }, []);

    

    return (
        <div id="modal-1" className="jw-modal open">
            <div className="jw-modal-body">
                <div className='cd-container' ref={myRef}>
                    <div className="cd-container-inner">
                        <div style={{ float: 'right', cursor: 'pointer' }} onClick={() => handleDetailCardClose(true)}>
                            <img src={close} alt="" />
                        </div>
                        <div className="sec1">
                            <div className='wkr-img'>
                                <img src={require(`../../images/${activeEquipment.image_raw}`)} alt="" style={{ borderRadius: '50%', maxWidth: '150px' }} />
                                {/*<img src={equipmentImage} alt=""/>*/}
                                <p className='wkr-name'>{activeEquipment.equipmentName}</p>
                                <div className="eq-id-wrap">
                                    <p className='wkr-id'>{activeEquipment.equipmentId}</p>
                                    <p className='wkr-id'>{activeEquipment.taskId}</p>
                                </div>

                            </div>

                            <div className="wkg-details">
                                <div className="wkgd1">
                                    <p className="wkgdp1a">5/{activeEquipment.taskProgress.completedTrips}</p>
                                    <PieChart
                                        series={[
                                            {
                                                data: [
                                                    { id: 0, value: (parseInt(activeEquipment.taskProgress.completedTrips) / 10) * 100, color: '#b3cf2d' },
                                                    { id: 1, value: (parseInt(5) / 10) * 100, color: '#959595' },

                                                ],
                                                valueFormatter: (v, { dataIndex }) => {
                                                    return `${Math.floor(v.value/10)}`;
                                                },
                                                innerRadius: 60, outerRadius: 48, cx: 65, cy: 58,

                                            },
                                        ]}
                                        width={150}
                                        height={128}
                                    />
                                    <p className="wkgdp2"><img src={ctl} alt="" /> Completed Task
                                        - {activeEquipment.taskProgress.completedTrips}</p>
                                    <p className="wkgdp3"><img src={rtl} alt="" /> Remaining Task - 05</p>
                                </div>
                                <div className="wkgd1">
                                    {activeEquipment?.scores?.safetyScore > 50 ? (
                                        <p className='p-ninety'>{activeEquipment?.scores?.safetyScore}</p>
                                    ) : <p className='p-fifty'>{activeEquipment?.scores?.safetyScore}</p>}
                                    <p className="wkgdp2a">Safety</p>
                                    <p className="wkgdp3">Score</p>
                                </div>

                                <div className="wkgd1">
                                    {activeEquipment?.scores?.productivityScore > 50 ? (
                                        <p className='p-ninety'>{activeEquipment?.scores?.productivityScore}</p>
                                    ) : <p className='p-fifty'>{activeEquipment?.scores?.productivityScore}</p>}
                                    <p className="wkgdp2a">Productivity</p>
                                    <p className="wkgdp3">Score</p>
                                </div>

                                <div className="wkgd1">
                                    <p className="wkgdp1-1" style={{ left: '25%' }}>Working <br></br> Hours</p>
                                    <PieChart
                                        series={[
                                            {
                                                data: [
                                                    { id: 0, value: (convertToMinutes(activeEquipment.workingHours.productiveTime) / 10) * 100, color: '#1c9e29' },
                                                    { id: 1, value: (convertToMinutes(activeEquipment.workingHours.idleTime) / 10) * 100, color: '#f1933b' },
                                                    { id: 2, value: (convertToMinutes(activeEquipment.workingHours.remainingTime) / 10) * 100, color: '#959595' },
                                                ],
                                                valueFormatter: (v, { dataIndex }) => {
                                                    return `${(v.value/60/10)}`;
                                                },
                                                innerRadius: 60, outerRadius: 48, cx: 60, cy: 60,
                                            },
                                        ]}
                                        width={150}
                                        height={128}
                                    />

                                    <div style={{minWidth:'120px'}}>
                                        <p className="wkgdp2" style={{ textAlign: 'left' }}><img src={ctl} alt="" /> Productive
                                            - {activeEquipment.workingHours.productiveTime}</p>
                                        <p className="wkgdp3" style={{ textAlign: 'left' }}><img src={ptl} alt="" /> Idle
                                            - {activeEquipment.workingHours.idleTime}</p>
                                        <p className="wkgdp3" style={{ textAlign: 'left' }}><img src={rtl} alt="" /> Remaining
                                            - {activeEquipment.workingHours.remainingTime}</p>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="sec-2">
                            <div className="sec-details-2">
                                <p className='sd-1'>{activeEquipment.assignedOperator.name}</p>
                                <p className='sd-2'><img src={fk1} alt="" /> Assigned Operator</p>
                            </div>
                            <div className="sec-details-2">
                                <p className='sd-1'>{activeEquipment.currentStatus.team}</p>
                                <p className='sd-2'><img src={equpt} alt="" /> Team</p>
                            </div>
                            <div className="sec-details-2">
                                <p className='sd-1'>{activeEquipment.currentStatus.task}</p>
                                <p className='sd-2'><img src={sdi1} alt="" /> Current Task</p>
                            </div>
                            <div className="sec-details-2">
                                <p className='sd-1'>{activeEquipment.workingHours.operatingHours ?? '-'}</p>
                                <p className='sd-2'><img src={whours} alt="" /> Operating Hours</p>
                            </div>
                            <div className="sec-details-2">
                                <p className='sd-1'>{activeEquipment.currentStatus.location}</p>
                                <p className='sd-2'><img src={sdi6} alt="" /> Current Location</p>
                            </div>

                        </div>


                        <div className="sec-3">
                            <div className="sec-details-2">
                                <p className='sd-1'>{activeEquipment.taskProgress.completedTrips}</p>
                                <p className='sd-2'><img src={eqtrip} alt="" /> Trips Completed</p>
                            </div>
                            <div className="sec-details-2">
                                <p className={`sd-1 ${activeEquipment?.taskProgress.status === 'Idle' ? 'idle' : ''}`}>
                                    {activeEquipment?.taskProgress.status}
                                </p>
                                <p className='sd-2'><img src={equstatus} alt="" /> Status</p>
                            </div>
                            <div className="sec-details-2">
                                <p className='sd-1'>{activeEquipment.currentStatus.maintenance.scheduledDate}</p>
                                <p className='sd-2'><img src={maintainance} alt="" />Scheduled Maintenance</p>
                            </div>
                        </div>


                        <div className="sec-4">
                            <div className="bar-sec">
                                <p className='b-p'>Safety Violations</p>
                                <BarChart
                                    tooltip={{ trigger: 'item' }}


                                    xAxis={[{
                                        scaleType: 'band',
                                        data: last7Days(),
                                    }]}
                                    series={[
                                        {
                                            data: getRandomArbitrary(),
                                            color: '#A35858',
                                        },
                                    ]}
                                    // leftAxis={null}
                                    width={400}
                                    height={150}
                                    margin={{ top: 5, bottom: 30, left: 55, right: 0 }}

                                />

                            </div>

                            <div className="bar-sec-2">
                                <div className="bar-sec-2-inner">
                                    <div className="p-wrap"><p className='b-p'>Tasks Journey</p></div>
                                    <div className="bar-parent">
                                        <div className="bar-wrap">
                                            {activeEquipment.taskJourney.map((task, index) =>
                                                <Fragment key={index}>
                                                    {task.status === 'Completed' && <div className={`ct-1`}></div>}
                                                    {task.status === 'In Progress' && <div className={`ct-2`}></div>}
                                                    {task.status === 'Upcoming' && <div className={`ct-3`}></div>}
                                                </Fragment>
                                            )}
                                            {activeEquipment.taskJourney.map((task, index) =>
                                                <>
                                                    {task.status === 'Completed' && (
                                                        <div className={`marker-${index + 1}  completed-Marker`}>
                                                        </div>
                                                    )}
                                                    {task.status === 'In Progress' && (
                                                        <div className={`marker-${index + 1} inProgress-Marker`}>
                                                        </div>
                                                    )}
                                                    {task.status === 'Upcoming' && task.status !== 'In Progress' && (
                                                        <div className={`marker-${index + 1} upcoming-Marker`}>
                                                        </div>
                                                    )}
                                                    {/*<div className={`marker-${index + 1} `} key={index}>*/}
                                                    {/*    <div className="mkr-g"></div>*/}
                                                    {/*</div>*/}
                                                </>
                                            )}

                                            {activeEquipment.taskJourney.map((task, index) =>
                                                <div className={`timer-${index + 1}`} key={index}>
                                                    <div className="tm1">
                                                        <p>{task.time}</p>
                                                    </div>
                                                </div>
                                            )}
                                            {activeEquipment.taskJourney.map((task, index) =>
                                                <div className={`t-com-${index + 1}`} key={index}>
                                                    {task.status === 'Completed' &&
                                                        <div className={`tcom`}>{task.status}</div>}
                                                    {task.status === 'In Progress' &&
                                                        <div className={`tpro`}>{task.status}</div>}
                                                    {task.status === 'Upcoming' &&
                                                        <div className={`upcoming`}>{task.status}</div>}
                                                </div>
                                            )}

                                            {/*<div className="bar-alert">*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default EquipmentDetails