import React, {useEffect} from 'react'
import "./CardInfo.css"
import loc from "../../images/location-logo.png"
import info from "../../images/info-logo.png"
import EmployeeDetails from './EmployeeDetails';
import empI from '../../images/Fed Chilton.svg'


function EmployeeList({workerList}) {

    const [activeWorker, setActiveWorker] = React.useState();


    const [showEmployeeDetail,setShowEmployeeDetail] = React.useState(false);
    const handleInfoIconClick = (e) => {
        //todo
        setShowEmployeeDetail(true);
    }
    const handleDetailCardClose = () => {
        setShowEmployeeDetail(false);
    }

    useEffect(() => {
        const el = document.getElementsByClassName('EmployeeList')[0];
        const observer = new window.IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                return
            }
            setShowEmployeeDetail(false);
        }, {
            root: null,
            threshold: 0.1, // set offset 0.1 means trigger if atleast 10% of element in viewport
        })

        observer.observe(el);

    }, []);


    return (
        <>
            {showEmployeeDetail && <EmployeeDetails activeEmployee={activeWorker} handleDetailCardClose={handleDetailCardClose}/>}
            <div className='emp_container EmployeeList'>
                <div className='emp_cards'>
                    {workerList.map((worker,key) =>
                        <div className='card_emp' key={key}>
                            <img className='info-img cursor' src={info} alt="" onClick={(e)=>{
                                handleInfoIconClick(e);
                                setShowEmployeeDetail(false);
                                setTimeout(()=>{
                                    setActiveWorker(worker);
                                    setShowEmployeeDetail(true);
                                },400);
                            }}/>
                            {/* <img className='emp-img' src={require(`../../images/${worker?.image_raw}`)} alt=""/> */}

                            {/* for worker image used static  */}
                            <img className='emp-img' alt='emp-img' src={empI}/>

                            <p className='emp-name'>{worker.worker_name??worker.name}</p>
                            <p className='emp-id'>{worker.worker_id ?? worker.employee_id} | {worker.worker_designation ?? worker.role}</p>
                            <p className='emp-loaction'>
                                <img src={loc} alt=""/> {worker.worker_location_title ?? worker.current_location}
                            </p>
                        </div>
                    )}

                </div>
            </div>
        </>
    )
}

export default EmployeeList
