import React, {useState} from 'react'
import "./SafetyInfractionsChart.css"
import { BarChart } from '@mui/x-charts/BarChart';
import { FilterType } from "./WorkerAnalyticsChart";
import {
    handleXAxisData,
    handleYAxisData,
} from "../../util/chartUtil";


function SafetyInfractionsChart({filterType, workerName}) {
    const [safetyInfractionFilter, setSafetyInfractionFilter] = useState(filterType === 'last_seven_days' ? FilterType.WEEK.toString() : FilterType.DAY.toString());
    const [safetyInfractionData, setSafetyInfractionData] = useState(handleYAxisData(FilterType.WEEK.toString()));

    const [securityInfractionFilter, setSecurityInfractionFilter] = useState(filterType === 'last_seven_days' ? FilterType.WEEK.toString() : FilterType.DAY.toString());
    const [securityInfractionData, setSecurityInfractionData] = useState(handleYAxisData(FilterType.WEEK.toString()));
    const [hoursOfOperationFilter, setHoursOfOperationFilter] = useState(filterType === 'last_seven_days' ? FilterType.WEEK.toString() : FilterType.DAY.toString());

    const [hoursOfOperationData, setHoursOfOperationData] = useState(handleYAxisData(FilterType.WEEK.toString()));

    const [productivityOperationFilter, setProductivityOperationFilter] = useState(filterType === 'last_seven_days' ? FilterType.WEEK.toString() : FilterType.DAY.toString());
    const [productivityOperationData, setProductivityOperationData] = useState(handleYAxisData(FilterType.WEEK.toString()));

    const [missingSafetyShoesFilter, setMissingSafetyShoesFilter] = useState(filterType === 'last_seven_days' ? FilterType.WEEK.toString() : FilterType.DAY.toString());
    const [missingSafetyShoesData, setMissingSafetyShoesData] = useState(handleYAxisData(FilterType.WEEK.toString()));


    return (
        <div className='chart-container'>
            <div className="chart-section-1">
                <div className="chart-1">
                    <div className="analytics-filter">
                        <p className='chart-heading'>Missing Helmet</p>
                        <div className="filter-sec">
                            <select onChange={(e) => {
                                setSafetyInfractionFilter(e.target.value)
                                setSafetyInfractionData(handleYAxisData(e.target.value.toString()))
                            }}
                                    defaultValue={safetyInfractionFilter}>
                                <option value={FilterType.DAY.toString()}
                                        selected={safetyInfractionFilter === FilterType.DAY.toString()}>{FilterType.DAY.toString()}</option>
                                <option value={FilterType.WEEK.toString()}
                                        selected={safetyInfractionFilter === FilterType.WEEK.toString()}>{FilterType.WEEK.toString()}</option>
                                <option value={FilterType.MONTH.toString()}
                                        selected={safetyInfractionFilter === FilterType.MONTH.toString()}>{FilterType.MONTH.toString()}</option>
                                <option value={FilterType.YEAR.toString()}
                                        selected={safetyInfractionFilter === FilterType.YEAR.toString()}>{FilterType.YEAR.toString()}</option>
                            </select>
                        </div>
                    </div>

                    <BarChart
                        xAxis={[{
                            scaleType: 'band',
                            data: handleXAxisData(safetyInfractionFilter)
                        }]}
                        series={[{data: safetyInfractionData, color: '#d97575'}]}
                        width={400}
                        height={300}
                    />

                </div>
                <div className="chart-2">
                    <div className="analytics-filter">
                        <p className='chart-heading'>Missing Safety Vest </p>
                        <div className="filter-sec">
                            <select onChange={(e) => {
                                setHoursOfOperationFilter(e.target.value)
                                setHoursOfOperationData(handleYAxisData(e.target.value.toString()))
                            }}
                                    defaultValue={hoursOfOperationFilter}>
                                <option value={FilterType.DAY.toString()}
                                        selected={hoursOfOperationFilter === FilterType.DAY.toString()}>{FilterType.DAY.toString()}</option>
                                <option value={FilterType.WEEK.toString()}
                                        selected={hoursOfOperationFilter === FilterType.WEEK.toString()}>{FilterType.WEEK.toString()}</option>
                                <option value={FilterType.MONTH.toString()}
                                        selected={hoursOfOperationFilter === FilterType.MONTH.toString()}>{FilterType.MONTH.toString()}</option>
                                <option value={FilterType.YEAR.toString()}
                                        selected={hoursOfOperationFilter === FilterType.YEAR.toString()}>{FilterType.YEAR.toString()}</option>
                            </select>
                        </div>
                    </div>

                    <BarChart
                        xAxis={[{
                            scaleType: 'band',
                            data: handleXAxisData(hoursOfOperationFilter)
                        }]}
                        series={[{
                            data: hoursOfOperationData,
                            color: '#9FEEA2'}]}
                        width={400}
                        height={300}
                    />

                </div>
                <div className="chart-3">
                    <div className="analytics-filter">
                        <p className='chart-heading'>Missing Safety Gloves </p>
                        <div className="filter-sec">
                            <select onChange={(e) => {
                                setSecurityInfractionFilter(e.target.value)
                                setSecurityInfractionData(handleYAxisData(e.target.value.toString()))
                            }}
                                    defaultValue={securityInfractionFilter}>
                                <option value={FilterType.DAY.toString()}
                                        selected={securityInfractionFilter === FilterType.DAY.toString()}>{FilterType.DAY.toString()}</option>
                                <option value={FilterType.WEEK.toString()}
                                        selected={securityInfractionFilter === FilterType.WEEK.toString()}>{FilterType.WEEK.toString()}</option>
                                <option value={FilterType.MONTH.toString()}
                                        selected={securityInfractionFilter === FilterType.MONTH.toString()}>{FilterType.MONTH.toString()}</option>
                                <option value={FilterType.YEAR.toString()}
                                        selected={securityInfractionFilter === FilterType.YEAR.toString()}>{FilterType.YEAR.toString()}</option>
                            </select>
                        </div>
                    </div>

                    <BarChart
                        xAxis={[{
                            scaleType: 'band',
                            data: handleXAxisData(securityInfractionFilter)
                        }]}
                        series={[{
                            data: securityInfractionData,
                            color: '#9FEEA2'
                        }]}
                        width={400}
                        height={300}
                    />

                </div>
                <div className="chart-4">
                    <div className="analytics-filter">
                        <p className='chart-heading'>Missing Safety Goggles </p>
                        <div className="filter-sec">
                            <select onChange={(e) => {
                                setProductivityOperationFilter(e.target.value)
                                setProductivityOperationData(handleYAxisData(e.target.value.toString()))
                            }}
                                    defaultValue={productivityOperationFilter}>
                                <option value={FilterType.DAY.toString()}
                                        selected={productivityOperationFilter === FilterType.DAY.toString()}>{FilterType.DAY.toString()}</option>
                                <option value={FilterType.WEEK.toString()}
                                        selected={productivityOperationFilter === FilterType.WEEK.toString()}>{FilterType.WEEK.toString()}</option>
                                <option value={FilterType.MONTH.toString()}
                                        selected={productivityOperationFilter === FilterType.MONTH.toString()}>{FilterType.MONTH.toString()}</option>
                                <option value={FilterType.YEAR.toString()}
                                        selected={productivityOperationFilter === FilterType.YEAR.toString()}>{FilterType.YEAR.toString()}</option>
                            </select>
                        </div>
                    </div>
                    <BarChart
                        xAxis={[{
                            scaleType: 'band',
                            data: handleXAxisData(productivityOperationFilter)
                        }]}
                        series={[
                            {
                                data: productivityOperationData,
                                area: true, color: '#6e801f'
                            },
                        ]}
                        width={400}
                        height={300}
                    />
                </div>
                <div className="chart-5">
                    <div className="analytics-filter">
                        <p className='chart-heading'>Missing Safety Shoes </p>
                        <div className="filter-sec">
                            <select onChange={(e) => {
                                setMissingSafetyShoesFilter(e.target.value)
                                setMissingSafetyShoesData(handleYAxisData(e.target.value.toString()))
                            }}
                                    defaultValue={missingSafetyShoesFilter}>
                                <option value={FilterType.DAY.toString()}
                                        selected={missingSafetyShoesFilter === FilterType.DAY.toString()}>{FilterType.DAY.toString()}</option>
                                <option value={FilterType.WEEK.toString()}
                                        selected={missingSafetyShoesFilter === FilterType.WEEK.toString()}>{FilterType.WEEK.toString()}</option>
                                <option value={FilterType.MONTH.toString()}
                                        selected={missingSafetyShoesFilter === FilterType.MONTH.toString()}>{FilterType.MONTH.toString()}</option>
                                <option value={FilterType.YEAR.toString()}
                                        selected={missingSafetyShoesFilter === FilterType.YEAR.toString()}>{FilterType.YEAR.toString()}</option>
                            </select>
                        </div>
                    </div>

                    <BarChart
                        xAxis={[{
                            scaleType: 'band',
                            data: handleXAxisData(missingSafetyShoesFilter)
                        }]}
                        series={[
                            {
                                data: missingSafetyShoesData,
                                area: true, color: '#6e801f'
                            },
                        ]}
                        width={400}
                        height={300}
                    />

                </div>
            </div>

        </div>
    )
}

export default SafetyInfractionsChart
