import React, { Fragment, useEffect, useState } from 'react'
import "./Floormap.css";
import EmployeeCard from "./employee/EmployeeCard";
import EquipmentCard from "./equipment/EquipmentCard";
import flrmap from "../images/floor-map.png"
import equipmentImage from "../images/normal_veh.svg"

function Floormap({ workerList, type, equipmentList }) {
  const workerData = [
    {
      name: 'Karen Chilton',
      id: 1,
      image: 'Karen Chilton.svg',
      image_raw: 'Karen Chilton.svg',
      loc: { x: '-35', y: '134' },
      label: 'Welding Area, Zone 1',
      team: 'Team 1',
      emp_id: 'WID#2144125',
      role: 'Field Engg.',
      worktype: 'Material Transport',
      machine: 'Forklift 1'
    },
    {
      name: 'Jack Fred',
      id: 2,
      image: 'Jack Fred.svg',
      image_raw: 'Jack Fred.svg',
      loc: { x: '-91', y: '250' },
      label: 'Construction, Zone 1',
      team: 'Team 1',
      emp_id: 'WID#2143135',
      role: 'Field Engg.',
      worktype: 'Material Transport',
      machine: 'Forklift 1'
    },
    {
      name: 'Wesly Tim',
      id: 3,
      image: 'Wesly Tim.svg',
      image_raw: 'Wesly Tim.svg',
      loc: { x: '86', y: '253' },
      label: 'Packaging, Zone 1',
      team: 'Team 1',
      emp_id: 'WID#2143295',
      role: 'Electrician',
      worktype: 'Material Transport',
      machine: 'Forklift 1'
    },
    {
      name: 'Fed Chilton',
      id: 4,
      image: 'Fed Chilton.svg',
      image_raw: 'Fed Chilton.svg',
      loc: { x: '71', y: '443' },
      label: 'Tower 3, Zone 1',
      team: 'Team 1',
      emp_id: 'WID#2143195',
      role: 'Forklift Opr.',
      worktype: 'Material Transport',
      machine: 'Forklift 1'
    },
    {
      name: 'Peter Bishop',
      id: 5,
      image: 'Peter Bishop.svg',
      image_raw: 'Peter Bishop.svg',
      loc: { x: '-221', y: '506' },
      label: 'Construction, Zone 1',
      team: 'Team 1',
      emp_id: 'WID#2143295',
      role: 'Field Engg.',
      worktype: 'Material Transport',
      machine: 'Forklift 1'
    },
    {
      name: 'Pannola Van',
      id: 6,
      image: 'Pannola Van.svg',
      image_raw: 'Pannola Van.svg',
      loc: { x: '-120', y: '628' },
      label: 'Tower 1, Zone 2',
      team: 'Team 1',
      emp_id: 'WID#2243195 ',
      role: 'Crane Opr.',
      worktype: 'Material Transport',
      machine: 'Forklift 1'
    },
    {
      name: 'Bruce Wayne',
      id: 7,
      image: 'Pannola Van.svg',
      image_raw: 'Pannola Van.svg',
      loc: { x: '-44', y: '342' },
      label: 'Tower 2, Zone 3',
      team: 'Team 1',
      emp_id: 'WID#2243195 ',
      role: 'Assembler',
      worktype: 'Material Transport',
      machine: 'Forklift 1'
    },
    {
      name: 'Lana Gordon',
      id: 8,
      image: 'Pannola Van.svg',
      image_raw: 'Pannola Van.svg',
      loc: { x: '-42', y: '502' },
      label: 'Tower 4, Zone 4',
      team: 'Team 1',
      emp_id: 'WID#2243195 ',
      role: 'Safety Specialist',
      worktype: 'Material Transport',
      machine: 'Forklift 1'
    },
    {
      name: 'Frank Miller',
      id: 9,
      image: 'Pannola Van.svg',
      image_raw: 'Pannola Van.svg',
      loc: { x: '120', y: '556' },
      label: 'Tower 1, Zone 2',
      team: 'Team 1',
      emp_id: 'WID#2243195 ',
      role: 'Worker',
      worktype: 'Material Transport',
      machine: 'Forklift 1'
    },
    {
      name: 'Amy Jackson',
      id: 10,
      image: 'Pannola Van.svg',
      image_raw: 'Pannola Van.svg',
      loc: { x: '80', y: '523' },
      label: 'Tower 1, Zone 4',
      team: 'Team 1',
      emp_id: 'WID#2243195 ',
      role: 'Worker',
      worktype: 'Material Transport',
      machine: 'Forklift 1'
    },
    {
      name: 'Selina Kyle',
      id: 11,
      image: 'Pannola Van.svg',
      image_raw: 'Pannola Van.svg',
      loc: { x: '-153', y: '770' },
      label: 'Tower 3, Zone 3',
      team: 'Team 1',
      emp_id: 'WID#2243195 ',
      role: 'Crane Opr.',
      worktype: 'Material Transport',
      machine: 'Forklift 1'
    },
    {
      name: 'Henry Cavil',
      id: 12,
      image: 'Pannola Van.svg',
      image_raw: 'Pannola Van.svg',
      loc: { x: '-214', y: '254' },
      label: 'Tower 1, Zone 2',
      team: 'Team 1',
      emp_id: 'WID#2243195 ',
      role: 'Worker.',
      worktype: 'Material Transport',
      machine: 'Forklift 1'
    },
    {
      name: 'Robin Grayson',
      id: 13,
      image: 'Pannola Van.svg',
      image_raw: 'Pannola Van.svg',
      loc: { x: '-220', y: '628' },
      label: 'Tower 1, Zone 2',
      team: 'Team 1',
      emp_id: 'WID#2243195 ',
      role: 'Worker',
      worktype: 'Material Transport',
      machine: 'Forklift 1'
    },
    {
      name: 'Joe Chill',
      id: 14,
      image: 'Pannola Van.svg',
      image_raw: 'Pannola Van.svg',
      loc: { x: '-5', y: '735' },
      label: 'Tower 1, Zone 2',
      team: 'Team 1',
      emp_id: 'WID#2243195 ',
      role: 'Forklift Opr.',
      worktype: 'Material Transport',
      machine: 'Forklift 1'
    },
    {
      name: 'Robert Pearson',
      id: 15,
      image: 'Pannola Van.svg',
      image_raw: 'Pannola Van.svg',
      loc: { x: '60', y: '679' },
      label: 'Tower 1, Zone 2',
      team: 'Team 1',
      emp_id: 'WID#2243195 ',
      role: 'Worker',
      worktype: 'Material Transport',
      machine: 'Forklift 1'
    },

  ];

  const [workerDataFiltered, setWorkerDataFiltered] = useState([]);
  useEffect(() => {
    if (workerList) {
      workerList.map((data, key) => {
        if (workerData[key]) {
          data.loc = workerData[key].loc;
        } else {
          data.loc = { x: '-44', y: '-334' }
        }
      })
      setWorkerDataFiltered(workerList);
    }
  }, [workerList]);



  const [currentWorkerXPosition, setCurrentWorkerXPosition] = React.useState(0);
  const [currentWorkerYPosition, setCurrentWorkerYPosition] = React.useState(0);
  const [activeWorker, setActiveWorker] = React.useState();

  const currentWorkerPosition = (e) => {
    setCurrentWorkerXPosition(e.pageX);
    setCurrentWorkerYPosition(e.pageY);
  }

  const [showEmployeeCard, setShowEmployeeCard] = React.useState(false);

  const handleWorkerCard = (e, worker) => {
    currentWorkerPosition(e);
    if (activeWorker?.employee_id === worker?.employee_id && showEmployeeCard) {
      setShowEmployeeCard(false);
    } else {
      setShowEmployeeCard(true);
      setActiveWorker(worker);
    }
  }

  const equipmentData = [
    {
      name: 'Forklift 5 ',
      id: 1,
      image: 'normal_veh.svg',
      image_raw: 'forklift1.svg',
      image_marker: 'forklift1.svg',
      Assigned_Operator: 'Karen Chilton',
      loc: { x: '-47', y: '287' },
      label: 'Welding Area, Zone 1',
      team: 'Team 1',
      EID: 'EID#2143195 ',
      JID: 'JID#06 .',
      worktype: 'Material Transport',
      machine: 'Forklift 1',
      maintenance_time: '10-14-2024',
      trips_completed: '10 Trips',
      operating_hours: '09 Hrs/day',
      status: 'Idle',
    },
    {
      name: 'Forklift 2',
      id: 2,
      image: 'cranesm.svg',
      image_raw: 'forklift1.svg',
      image_marker: 'forklift1.svg',
      Assigned_Operator: 'Jack Fred',
      loc: { x: '-25', y: '361' },
      label: 'Construction, Zone 1',
      team: 'Team 2',
      EID: 'EID#2143195 ',
      JID: 'JID#07 .',
      worktype: 'Material Transport',
      machine: 'Forklift 1',
      maintenance_time: '11-11-2024',
      trips_completed: '5 Trips',
      operating_hours: '08 Hrs/day',
      status: 'Productive',
    },
    {
      name: 'Excavator 1',
      id: 3,
      image: 'normal_veh.svg',
      image_raw: 'forklift1.svg',
      image_marker: 'forklift1.svg',
      Assigned_Operator: 'Wesly Tim',
      loc: { x: '-130', y: '516' },
      label: 'Packaging, Zone 1',
      team: 'Team 3',
      EID: 'EID#2143195 ',
      JID: 'JID#08 .',
      worktype: 'Material Transport',
      machine: 'Forklift 1',
      maintenance_time: '11-25-2024',
      trips_completed: '8 Trips',
      operating_hours: '10 Hrs/day',
      status: 'Remaining',
    },
    {
      name: 'Loader#243',
      id: 4,
      image: 'alert_veh.svg',
      image_raw: 'forklift1.svg',
      image_marker: 'forklift1.svg',
      Assigned_Operator: 'Fed Chilton',
      loc: { x: '6', y: '513' },
      label: 'Tower 3, Zone 1',
      team: 'Team 4',
      EID: 'EID#2143195 ',
      JID: 'JID#09 .',
      worktype: 'Material Transport',
      machine: 'Forklift 1',
      maintenance_time: '11-23-2024',
      trips_completed: '15 Trips',
      operating_hours: '08 Hrs/day',
      status: 'Idle',
    },
    {
      name: 'Scissor Lift 1',
      id: 5,
      image: 'normal_veh.svg',
      image_raw: 'forklift1.svg',
      image_marker: 'forklift1.svg',
      Assigned_Operator: 'Peter Bishop',
      loc: { x: '40', y: '561' },
      label: 'Construction, Zone 1',
      team: 'Team 5',
      EID: 'EID#2143195 ',
      JID: 'JID#10 .',
      worktype: 'Material Transport',
      machine: 'Forklift 1',
      maintenance_time: '10-25-2024',
      trips_completed: '6 Trips',
      operating_hours: '07 Hrs/day',
      status: 'Productive',
    },
    {
      name: 'Crane 1',
      id: 6,
      image: 'alert_veh.svg',
      image_raw: 'forklift1.svg',
      image_marker: 'forklift1.svg',
      Assigned_Operator: 'Pannola Van',
      loc: { x: '-194', y: '578' },
      label: 'Tower 1, Zone 2',
      team: 'Team 6',
      EID: 'EID#2143195 ',
      JID: 'JID#11 .',
      worktype: 'Material Transport',
      machine: 'Forklift 1',
      maintenance_time: '10-21-2024',
      trips_completed: '12 Trips',
      operating_hours: '09 Hrs/day',
      status: 'Remaining',
    },
    {
      name: 'Crane 1',
      id: 7,
      image: 'alert_veh.svg',
      image_raw: 'forklift1.svg',
      image_marker: 'forklift1.svg',
      Assigned_Operator: 'Pannola Van',
      loc: { x: '-96', y: '632' },
      label: 'Tower 1, Zone 2',
      team: 'Team 6',
      EID: 'EID#2143195 ',
      JID: 'JID#11 .',
      worktype: 'Material Transport',
      machine: 'Forklift 1',
      maintenance_time: '10-21-2024',
      trips_completed: '12 Trips',
      operating_hours: '09 Hrs/day',
      status: 'Remaining',
    },
  ];

  const [equipmentDataFiltered, setEquipmentDataFiltered] = useState([]);
  useEffect(() => {
    if (equipmentList) {
      equipmentList.map((data, key) => {
        if (equipmentData[key]) {
          data.loc = equipmentData[key].loc;
        } else {
          data.loc = { x: '-44', y: '-334' }
        }
      })
      setEquipmentDataFiltered(equipmentList);
    }
  }, [equipmentList]);

  const [currentEquipmentXPosition, setCurrentEquipmentXPosition] = React.useState(0);
  const [currentEquipmentYPosition, setCurrentEquipmentYPosition] = React.useState(0);
  const [activeEquipment, setActiveEquipment] = React.useState();

  const currentEquipmentPosition = (e) => {
    setCurrentEquipmentXPosition(e.pageX);
    setCurrentEquipmentYPosition(e.pageY);
  }

  const [showEquipmentCard, setShowEquipmentCard] = React.useState(false);

  const handleEquipmentCard = (e, equipment) => {
    currentEquipmentPosition(e);
    if (activeEquipment?.equipmentId === equipment?.equipmentId && showEquipmentCard) {
      setShowEquipmentCard(false);
    } else {
      setShowEquipmentCard(true);
      setActiveEquipment(equipment);
    }
  }


  useEffect(() => {
    const el = document.getElementsByClassName('floopMap')[0];
    const observer = new window.IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        return
      }
      setShowEmployeeCard(false);
      setShowEquipmentCard(false);
    }, {
      root: null,
      threshold: 0.1, // set offset 0.1 means trigger if atleast 10% of element in viewport
    })

    observer.observe(el);

  }, []);

  return (
    <>
      {activeWorker && showEmployeeCard && <EmployeeCard x={currentWorkerXPosition} y={currentWorkerYPosition} activeWorker={activeWorker} handleParentCardClose={() => setShowEmployeeCard(false)} />}
      {activeEquipment && showEquipmentCard && <EquipmentCard x={currentEquipmentXPosition} y={currentEquipmentYPosition} activeEquipment={activeEquipment} handleParentCardClose={() => setShowEquipmentCard(false)} />}
      <div className='floor-container floopMap'>

        <div className="floor-map">
          <div className="workers">
            {type === 'employee' && workerDataFiltered.map((worker, key) =>
              <Fragment key={key}>
                <img src={require(`../images/${worker?.image_raw}`)} alt=""
                  className='wk-1 wk-hov'
                  style={{ top: worker.loc.x + 'px', left: worker.loc.y + 'px', zIndex: 996 }}
                  onClick={(e) => handleWorkerCard(e, worker)} />
              </Fragment>
            )}

            {type === 'equipment' && equipmentDataFiltered.map((equipment, key) =>
              <Fragment key={key}>
                {/* <img src={equipmentImage} */}
                <img src={require(`../images/${equipment?.image_marker}`)}               
                  alt=""
                  className='wk-1 eq-hov'
                  style={{ top: equipment.loc.x + 'px', left: equipment.loc.y + 'px' }}
                  onClick={(e) => handleEquipmentCard(e, equipment)}
                />
              </Fragment>
            )}
          </div>
          <div className="inner-floor">
            <img src={flrmap} alt="" />
          </div>

        </div>
      </div>
    </>
  )
}

export default Floormap
