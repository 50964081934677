import './stylev2.css';
import RightSideBar from "./components/RightSideBar";
import LeftMenu from "./components/LeftMenu";
import LogoWrapper from "./components/LogoWrapper";
import ChatWrapper from "./components/ChatWrapper";
import {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import {useNavigate} from "react-router-dom";
import {Constants}  from './Constants'
import { useStore} from "react-redux";



function App() {
    let navigate = useNavigate();
    const [cookies] = useCookies([Constants.COOKIE_TOKEN]);
    useEffect(() => {
        if(!cookies.token){
            return navigate("/login");
        }
        let decodedValue = window.atob(cookies.token);

        if(decodedValue !== 'admin_admin'){
            return navigate("/login");
        }
    },[cookies, navigate]);

    const store = useStore();
    const [chatBucket , setChatBucket] = useState(store.getState().chats);
    useEffect(()=> {
        setChatBucket(store.getState().chats)
    },[store]);

    const pokeSibling = () => {
        setChatBucket(store.getState().chats);
    }

    useEffect(() => {
        console.debug('APP:VERSION:',process.env.REACT_APP_VERSION);
    }, []);

    // useEffect(() => {
    //     axios(Constants.API_URL)
    //         .then((response) => {
    //             const newData = {data:response.data};
    //             dispatch(addAllData(newData));
    //         })
    // }, []);

    return (
        <div className="container">
            <LogoWrapper/>
            <LeftMenu/>
            <ChatWrapper pokeSibling={pokeSibling}/>
            <RightSideBar chatBucket={chatBucket}/>
        </div>
    );
}

export default App;
