/**
 *
 * @param str
 * @returns {{num1: number, num2: number}}
 */
export const getLatLong = (str) => {
    const match = str.match(/\((-?\d+),\s*(-?\d+)\)/);
    let num1 = 0;
    let num2 = 0;
    if (match) {
        num1 = parseInt(match[1], 10); // First number
        num2 = parseInt(match[2], 10); // Second number

        return {num1, num2};
    } else {
        return {num1, num2};
    }
}