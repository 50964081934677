import React, { useState } from 'react'
import "./WorkerAnalyticsChart.css"
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { handleXAxisData, handleYAxisData } from "../../util/chartUtil";


function WorkerAnalyticsChart({filterType, workerName}) {
    const [safetyInfractionFilter, setSafetyInfractionFilter] = useState(filterType === 'last_seven_days' ? FilterType.WEEK.toString() : FilterType.DAY.toString());
    const [safetyInfractionData, setSafetyInfractionData] = useState(handleYAxisData(FilterType.WEEK.toString()));

    const [securityInfractionFilter, setSecurityInfractionFilter] = useState(filterType === 'last_seven_days' ? FilterType.WEEK.toString() : FilterType.DAY.toString());
    const [securityInfractionData, setSecurityInfractionData] = useState(handleYAxisData(FilterType.WEEK.toString()));
    const [hoursOfOperationFilter, setHoursOfOperationFilter] = useState(filterType === 'last_seven_days' ? FilterType.WEEK.toString() : FilterType.DAY.toString());

    const [hoursOfOperationData, setHoursOfOperationData] = useState(handleYAxisData(FilterType.WEEK.toString()));


    const [productivityOperationFilter, setProductivityOperationFilter] = useState(filterType === 'last_seven_days' ? FilterType.WEEK.toString() : FilterType.DAY.toString());
    const [productivityOperationData, setProductivityOperationData] = useState(handleYAxisData(FilterType.WEEK.toString()));

    return (
        <div className='chart-container'>
            <div className="chart-section-1">
                <div className="chart-1">
                    <div className="analytics-filter">
                        <p className='chart-heading'>Safety Infraction</p>
                        <div className="filter-sec">
                            <select onChange={(e) => {
                                setSafetyInfractionFilter(e.target.value)
                                setSafetyInfractionData(handleYAxisData(e.target.value.toString()))
                            }}
                                    defaultValue={safetyInfractionFilter}>
                                <option value={FilterType.DAY.toString()}
                                        selected={safetyInfractionFilter === FilterType.DAY.toString()}>{FilterType.DAY.toString()}</option>
                                <option value={FilterType.WEEK.toString()}
                                        selected={safetyInfractionFilter === FilterType.WEEK.toString()}>{FilterType.WEEK.toString()}</option>
                                <option value={FilterType.MONTH.toString()}
                                        selected={safetyInfractionFilter === FilterType.MONTH.toString()}>{FilterType.MONTH.toString()}</option>
                                <option value={FilterType.YEAR.toString()}
                                        selected={safetyInfractionFilter === FilterType.YEAR.toString()}>{FilterType.YEAR.toString()}</option>
                            </select>
                        </div>
                    </div>

                    <BarChart
                        xAxis={[{
                            scaleType: 'band',
                            data: handleXAxisData(safetyInfractionFilter)
                        }]}
                        series={[{
                            data: safetyInfractionData,
                            color: '#d97575'
                        }]}
                        width={450}
                        height={300}
                    />

                </div>
                <div className="chart-2">
                    <div className="analytics-filter">
                        <p className='chart-heading'>Security Infraction </p>
                        <div className="filter-sec">
                            <select onChange={(e) => {
                                setSecurityInfractionFilter(e.target.value)
                                setSecurityInfractionData(handleYAxisData(e.target.value.toString()))
                            }}
                                    defaultValue={securityInfractionFilter}>
                                <option value={FilterType.DAY.toString()}
                                        selected={securityInfractionFilter === FilterType.DAY.toString()}>{FilterType.DAY.toString()}</option>
                                <option value={FilterType.WEEK.toString()}
                                        selected={securityInfractionFilter === FilterType.WEEK.toString()}>{FilterType.WEEK.toString()}</option>
                                <option value={FilterType.MONTH.toString()}
                                        selected={securityInfractionFilter === FilterType.MONTH.toString()}>{FilterType.MONTH.toString()}</option>
                                <option value={FilterType.YEAR.toString()}
                                        selected={securityInfractionFilter === FilterType.YEAR.toString()}>{FilterType.YEAR.toString()}</option>
                            </select>
                        </div>
                    </div>

                    <BarChart
                        xAxis={[{
                            scaleType: 'band',
                            data: handleXAxisData(securityInfractionFilter)
                        }]}
                        series={[{
                            data: securityInfractionData,
                            color: '#9FEEA2'
                        }]}
                        width={450}
                        height={300}
                    />

                </div>
                <div className="chart-3">
                    <div className="analytics-filter">
                        <p className='chart-heading'>Hours of Operation (Hrs) </p>
                        <div className="filter-sec">
                            <select onChange={(e) => {
                                setHoursOfOperationFilter(e.target.value)
                                setHoursOfOperationData(handleYAxisData(e.target.value.toString()))
                            }}
                                    defaultValue={hoursOfOperationFilter}>
                                <option value={FilterType.DAY.toString()}
                                        selected={hoursOfOperationFilter === FilterType.DAY.toString()}>{FilterType.DAY.toString()}</option>
                                <option value={FilterType.WEEK.toString()}
                                        selected={hoursOfOperationFilter === FilterType.WEEK.toString()}>{FilterType.WEEK.toString()}</option>
                                <option value={FilterType.MONTH.toString()}
                                        selected={hoursOfOperationFilter === FilterType.MONTH.toString()}>{FilterType.MONTH.toString()}</option>
                                <option value={FilterType.YEAR.toString()}
                                        selected={hoursOfOperationFilter === FilterType.YEAR.toString()}>{FilterType.YEAR.toString()}</option>
                            </select>
                        </div>
                    </div>

                    <BarChart
                        xAxis={[{
                            scaleType: 'band',
                            data: handleXAxisData(hoursOfOperationFilter)
                        }]}
                        series={[{
                            data: hoursOfOperationData,
                            color: '#D9975A'
                        }]}
                        width={450}
                        height={300}
                    />

                </div>
                <div className="chart-4">
                    <div className="analytics-filter">
                        <p className='chart-heading'>Productivity (%)</p>
                        <div className="filter-sec">
                            <select onChange={(e) => {
                                setProductivityOperationFilter(e.target.value)
                                setProductivityOperationData(handleYAxisData(e.target.value.toString()))
                            }}
                                    defaultValue={productivityOperationFilter}>
                                <option value={FilterType.DAY.toString()}
                                        selected={productivityOperationFilter === FilterType.DAY.toString()}>{FilterType.DAY.toString()}</option>
                                <option value={FilterType.WEEK.toString()}
                                        selected={productivityOperationFilter === FilterType.WEEK.toString()}>{FilterType.WEEK.toString()}</option>
                                <option value={FilterType.MONTH.toString()}
                                        selected={productivityOperationFilter === FilterType.MONTH.toString()}>{FilterType.MONTH.toString()}</option>
                                <option value={FilterType.YEAR.toString()}
                                        selected={productivityOperationFilter === FilterType.YEAR.toString()}>{FilterType.YEAR.toString()}</option>
                            </select>
                        </div>
                    </div>
                    <LineChart
                        xAxis={[{
                            scaleType: 'band',
                            data: handleXAxisData(productivityOperationFilter)
                        }]}
                        yAxis={[{
                            min: 0,
                            max: 100,
                            interval: 20,
                        }]}
                        series={[
                            {
                                data: productivityOperationData,
                                area: true, color: '#6e801f',
                                valueFormatter: (v) => (v === null ? '' : v+'%'),
                            },
                        ]}
                        width={450}
                        height={300}
                    />
                </div>
            </div>
            <div className="chart-section-1">
            </div>

        </div>
    )
}

export const FilterType = Object.freeze({
    DAY: ("Day"),
    WEEK: ("Week"),
    MONTH: ("Month"),
    YEAR: ("Year")
});
export default WorkerAnalyticsChart
