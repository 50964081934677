import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ErrorPage from "./error-page";
import reportWebVitals from './reportWebVitals';
import store from "./store/store";
import {Provider} from "react-redux";
import Login from "./pages/Login";
import EmployeeList from './components/employee/EmployeeList';
import Floormap from './components/Floormap';
import AlertFloorMap from './components/AlertFloorMap';
import EquipmentAnalyticsChart from './components/Analytics/EquipmentAnalyticsChart';
import WorkerAnalyticsChart from './components/Analytics/WorkerAnalyticsChart';
import IncidentList from './components/incident/IncidentList';
import SafetyInfractions from './components/incident/SafetyInfractions'
import WorkPathMap from './components/employee/WorkPathMap'
import EquipmentList from './components/equipment/EquipmentList'
import SafetyInfractionsChart from './components/Analytics/SafetyInfractionsChart';


const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <ErrorPage/>,
    },
    {
        path: "/login",
        element: <Login/>,
    },
    {
        path: "/compass",
        element: <p>Compass</p>,
    },
    {
        path: "/EmployeeList",
        element: <EmployeeList/>,
    },
    {
        path: "/Floormap",
        element: <Floormap/>,
    },
    {
        path: "/AlertFloorMap",
        element: <AlertFloorMap/>,
    },
  
    {
        path: "/EquipmentAnalyticsChart",
        element: <EquipmentAnalyticsChart/>,
    },
    {
        path: "/IncidentList",
        element: <IncidentList/>,
    },
    ,
    {
        path: "/SafetyInfractions",
        element: <SafetyInfractions/>,
    },
    
    {
        path: "/WorkPathMap",
        element: <WorkPathMap/>,
    },
    {
        path: "/EquipmentList",
        element: <EquipmentList/>,
    },
    {
        path: "/WorkerAnalyticsChart",
        element: <WorkerAnalyticsChart/>,
    },
    {
        path: "/SafetyInfractionsChart",
        element: <SafetyInfractionsChart/>,
    },
    {
        path: '*',
        element: <p>404 Error - Nothing here...</p>
    }

]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router}/>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
