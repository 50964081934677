import React, {useState, useEffect, useRef} from 'react'
import '../incident/IncidentDetails.css'
import user from "../../images/user.svg"
import todo from "../../images/todo.svg"
import loc from "../../images/location-logo.png"
import status from "../../images/time.svg"
// import captureimage from "../../images/missing-helmet-img.png"
import ReactPlayer from 'react-player'
import close from "../../images/close.svg"
import BlurryLoadingImage from './BlurryLoadingImage'

function IncidentDetails({activeIncident,handleDetailCardClose}) {
    const myRef = useRef(null);
    const [playing, setPlaying] = useState(false);
    // eslint-disable-next-line no-empty-pattern
    const [] = useState(true);

    const handlePlayerClick = () => {
        setPlaying(true);
    };

    // const handlePlayerReady = () => {
    //     setLoading(false);
    // };

    const executeScroll = () => myRef.current.scrollIntoView({behavior:'smooth'});
    useEffect(() => {
        executeScroll();
    }, [activeIncident]);
    useEffect(() => {
        document.body.classList.add('jw-modal-open');

        return () => document.body.classList.remove('jw-modal-open');
    }, []);

    return (
        <div id="modal-1" className="jw-modal open">
            <div className="jw-modal-body">
                <div className='incident-det-container' style={{position: 'relative', zIndex: 999999}} ref={myRef}>
                    <div style={{position: 'absolute', cursor: 'pointer', left: '95%', marginTop: '15px'}}
                         onClick={() => handleDetailCardClose(true)}>
                        <img src={close} alt=""/>
                    </div>
                    <div className="incident-det-container-inner">
                        <p className='para-1'>{activeIncident.name}</p>
                        <p className='para-2'>{activeIncident.alert_type_level}</p>
                        <div className="incident-points">
                            <p className='parameter'><img src={user} alt=""/>{activeIncident.operator}</p>
                            <p className='parameter'><img src={todo} alt=""/>{activeIncident.work_type}</p>
                            <p className='parameter'><img src={loc} alt=""/>{activeIncident.label}</p>
                            <p className='parameter'><img src={status} alt=""/>{activeIncident.alert_date_time}</p>
                        </div>
                        <div className="capture-incident">
                            <div className="capture-image">
                                {/* <img
                                    src={require(`../../images/${activeIncident.capture_image}`)}
                                    alt=""
                                    style={{ height: '384px', width: '577px',objectFit: 'cover' }}
                                /> */}
                                <BlurryLoadingImage
                                    preview={require(`../../images/${activeIncident.capture_image}`)}
                                    image={require(`../../images/${activeIncident.capture_image}`)}
                                    alt="Capture"
                                    imageStyleClass=""
                                    divStyleClass=""
                                    height="384px"
                                    width="577px"
                                />
                            </div>
                            <div className="capture-video">
                                {/* {loading && <div className="loading-spinner">Loading...</div>} */}
                                <ReactPlayer
                                    // light={require(`../../images/${activeIncident.capture_image}`)}
                                    light={
                                        <BlurryLoadingImage
                                            preview={require(`../../images/${activeIncident.capture_image}`)}
                                            image={require(`../../images/${activeIncident.capture_image}`)}
                                            alt=""
                                            imageStyleClass=""
                                            divStyleClass=""
                                            height="384px"
                                            width="577px"
                                        />}
                                    controls={true}
                                    url={require(`../../images/${activeIncident.capture_video}`)}
                                    height="384px"
                                    width="577px"
                                    muted
                                    // onReady={handlePlayerReady}
                                    onClick={handlePlayerClick}
                                    playing={playing}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default IncidentDetails
