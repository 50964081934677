import React, { useEffect, useRef, useState } from 'react'
import "./IncidentCard.css"
import IncidentDetails from "./IncidentDetails"
import infocap from "../../images/info-cap.svg"
import mtype from "../../images/todo.svg"
import lz from "../../images/location-logo.png"
import user from "../../images/inciuser.svg"
import timeline from "../../images/inctimline.svg"
import ReactPlayer from 'react-player'
import { getBreakpoint } from "../../util/responsive"
import BlurryLoadingImage from './BlurryLoadingImage'


function IncidentCard({ x, y, activeIncident, handleParentClose }) {


    const [showIncidentDetail, setShowIncidentDetail] = React.useState(false);
    const handleInfoIconClick = (e) => {
        //todo
        setShowIncidentDetail(true);
    }

    const myRef = useRef(null);
    const handleDetailCardClose = () => {
        if (myRef !== null && !showIncidentDetail) {
            const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' });
            executeScroll();
        }
        handleParentClose(true);
        setShowIncidentDetail(false);
        setLeftSideOverflow(0);
        setRightSideOverflow(0);
    }

    const [rightSideOverflow, setRightSideOverflow] = useState(0);
    const [leftSideOverflow, setLeftSideOverflow] = useState(0);
    const [playing, setPlaying] = useState(false);

    const handlePlayerClick = () => {
        setPlaying(true);
    };

    useEffect(() => {
        const ele = document.getElementsByClassName('worker-card-incident')[0].getBoundingClientRect();
        const elParent = document.getElementsByClassName('main-content-alets')[0].offsetWidth;
        setLeftSideOverflow(0);
        setRightSideOverflow(0);
        if (ele.right >= elParent) {
            //overflowing right side
            setRightSideOverflow(ele.right - elParent)
            setLeftSideOverflow(0);
        } else if (ele.left >= elParent) {
            //overflowing left side
            setLeftSideOverflow(ele.left - elParent)
            setRightSideOverflow(0);
        }
    }, [activeIncident]);

    function updateBreakpointOnResize() {
        const breakpoint = getBreakpoint();
    }

    useEffect(() => {
        // Listen for window resize event
        window.addEventListener('resize', updateBreakpointOnResize);

        // Initial check
        updateBreakpointOnResize();
    }, [])

    function calculateOffset() {
        if (getBreakpoint() === 'desktop-exception') {
            return -200;
        }
        if (getBreakpoint() === 'desktop-higher') {
            return -120;
        }
        if (getBreakpoint() === 'desktop-small-up') {
            return -350;
        }
        if (getBreakpoint() === 'desktop') {
            return 230;
        }
        return getBreakpoint() === 'desktop' ? 230 : 330
    }
    const playerHeight = "92px";
    const playerWidth = "160px";

    return (
        <>
            {showIncidentDetail &&
                <IncidentDetails activeIncident={activeIncident} handleDetailCardClose={handleDetailCardClose}/>}
            <div className='card-container-incident' style={{position: 'relative', left: '190px', top: '142px'}}
                 ref={myRef}>
                <div className='worker-card-incident'
                     style={{
                         zIndex: '997',
                         position: 'absolute',
                         top: (parseInt(activeIncident.loc.x) + 230) + 'px',
                         left: (rightSideOverflow > 1 ? (rightSideOverflow + calculateOffset()) : (parseInt(activeIncident.loc.y) + 50)) + 'px'
                     }}>
                    <div className='wc-container-incident'>

                        <div className='wc-wrapper-2-incident'>
                            <div className='det-1'>
                                <div className="ic-head"><p className='w-name'
                                                            title={activeIncident.name}>{activeIncident.name}</p></div>
                                <div className="ic-info-logo"><img src={infocap} alt="" className="pointer"
                                                                   onClick={handleInfoIconClick}/></div>

                            </div>


                            <div className='det-2'>
                                <p className='w-id'>{activeIncident.alert_type_level}</p>
                            </div>

                            <div className='det-3'>
                                <div className="w1"><p className='work-a'><img src={user}
                                                                               alt=""/> {activeIncident.operator}</p>
                                </div>
                                <div className="w2"><p className='work-a'><img src={mtype}
                                                                               alt=""/> {activeIncident.work_type}</p>
                                </div>

                            </div>

                            <div className='det-4'>
                                <div className="w1">
                                    <p className='work-a'>
                                        <img src={lz} alt=""/> {activeIncident.label}
                                    </p>
                                </div>
                                <div className="w2">
                                    <p className='work-a'>
                                        <img src={timeline}
                                             alt=""/> {activeIncident.alert_date_time}
                                    </p>
                                </div>

                            </div>

                            <div className="det-5">
                                <div className="sm-capture-image">
                                    <BlurryLoadingImage
                                        preview={require(`../../images/${activeIncident.capture_image}`)}
                                        image={require(`../../images/${activeIncident.capture_image}`)}
                                        alt="Capture"
                                        imageStyleClass=""
                                        divStyleClass=""
                                        height={playerHeight}
                                        width={playerWidth}
                                    />
                                </div>
                                <div className="sm-capture-video">
                                    <div className="sm-capture-video">
                                        <ReactPlayer
                                            // light={require(`../../images/${activeIncident.capture_image}`)}
                                            light={
                                                <BlurryLoadingImage
                                                    preview={require(`../../images/${activeIncident.capture_image}`)}
                                                    image={require(`../../images/${activeIncident.capture_image}`)}
                                                    alt=""
                                                    imageStyleClass=""
                                                    divStyleClass=""
                                                    height={playerHeight}
                                                    width={playerWidth}
                                                />}
                                            controls={true}
                                            url={require(`../../images/${activeIncident.capture_video}`)}
                                            height={playerHeight}
                                            width={playerWidth}
                                            muted
                                            onClick={handlePlayerClick}
                                            playing={playing}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IncidentCard
