export const showSnackBar = (marginLeft) => {
    // Get the snackbar DIV
    let x = document.getElementById("snackbar");

    if(x) {

        // Add the "show" class to DIV
        x.className = "show";
        x.style = `margin-left:${marginLeft}`;

        // After 3 seconds, remove the show class from DIV
        setTimeout(function () {
            x.className = x.className.replace("show", "");
        }, 3000);
    }

    return true;
}