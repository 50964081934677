import React from 'react'
import '../components/ChatShimmer.css'

function ChatShimmer() {
  return (
    <div className='chat-container'>
      <div className='chat-loader'>
        <div className='bar-1'></div>
        <div className='bar-2'></div>
        <div className='bar-3'></div>
      </div>
    </div>
  )
}

export default ChatShimmer
