import EmployeeList from "./components/employee/EmployeeList";
import Floormap from "./components/Floormap";
import SafetyInfractions from "./components/incident/SafetyInfractions";
import WorkPathMap from "./components/employee/WorkPathMap";
import EquipmentList from "./components/equipment/EquipmentList";
import AlertFloorMap from "./components/AlertFloorMap";
import EquipmentAnalyticsChart from "./components/Analytics/EquipmentAnalyticsChart";
import WorkerAnalyticsChart from "./components/Analytics/WorkerAnalyticsChart";
import SafetyInfractionsChart from "./components/Analytics/SafetyInfractionsChart";
import React from "react";

export const Constants = {
    // API_URL: 'http://localhost:8080/api.php',
    // API_URL: 'https://genai.workersafety.aiotdemos.com/genai/api/v1/ai',
    API_URL: 'https://grok-site-ai.uc.r.appspot.com/v1/org/OID1/dashboard',
    COOKIE_TOKEN: 'token',
    EMAIL: 'admin',
    PASSWORD: 'admin',
};


// Map specific commands to components
export const componentMap = [
    {
        'query': "Show people who are inside facility premises",
        'response_msg': "People inside facility premises are listed below:",
        'component': <EmployeeList/>,
        'meta': {},
        'component_name': "EmployeeList",
    },
    {
        'query': "Show the workers on construction facility map",
        'response_msg': "Construction facility map with workers:",
        'component': <Floormap/>,
        'meta': {'type': 'employee'},
        'component_name': "Floormap",
    }, {
        'query': "Show all the equipment in facility map",
        'response_msg': "Here is the facility map with equipment",
        'component': <Floormap/>,
        'meta': {'type': 'equipment'},
        'component_name': "Floormap",
    },
    {
        'query': "Show yesterday's safety infractions",
        'response_msg': "Here is yesterday's safety infractions",
        'component': <SafetyInfractions/>,
        'meta': {},
        'component_name': "SafetyInfractions",
    },
    {
        'query': "Show the Movement of Peter Bishop inside facility premises",
        'response_msg': "Here is the Movement of Peter Bishop inside facility premises",
        'component': <WorkPathMap/>,
        'meta': {},
        'component_name': "WorkPathMap",
    },

    {
        'query': "Show the Equipment inside facility premises",
        'response_msg': "Equipment inside facility premises listed below:",
        'component': <EquipmentList/>,
        'meta': {},
        'component_name': "EquipmentList",
    },

    {
        'query': "Show all alerts for the last 24 hours on facility map.",
        'response_msg': "Here is the alerts for the last 24 hours on facility map.",
        'component': <AlertFloorMap/>,
        'meta': {},
        'component_name': "AlertFloorMap",
    },
    {
        'query': "Show last 7 days analytics for Forklift 1",
        'response_msg': "Here is the last 7 days analytics for Forklift 1",
        'component': <EquipmentAnalyticsChart/>,
        'meta': {'filterType': 'last_seven_days'},
        'component_name': "EquipmentAnalyticsChart",
    },
    {
        'query': "Show last 7 days analytics for Peter Bishop.",
        'response_msg': "Here is the last 7 days analytics for Peter Bishop.",
        'component': <WorkerAnalyticsChart/>,
        'meta': {'filterType': 'last_seven_days'},
        'component_name': "WorkerAnalyticsChart",
    },
    {
        'query': "Show last 7 days analytics for all type of Safety Infractions.",
        'response_msg': "Here is the last 7 days analytics for Safety Infractions.",
        'component': <SafetyInfractionsChart/>,
        'meta': {'filterType': 'last_seven_days'},
        'component_name': "SafetyInfractionsChart",
    },
];