import React from 'react'
import style from "./IncidentList.module.css"
import IncidentDetails from './IncidentDetails'
import infocap from "../../images/info-cap.svg"
import mtype from "../../images/todo.svg"
import lz from "../../images/location-logo.png"
import user from "../../images/inciuser.svg"
import timeline from "../../images/inctimline.svg"


function IncidentList() {
    const alertData = [
        {
            name:'Missing Helmet',
            id: 1,
            image: 'alert_icon.svg',
            capture_image:'missing-helmet-img.png',
            capture_video:'missing-helmet.mp4',
            cp_image:'sm-cp-img.png',
            loc: { x: '74', y: '-280' },
            label: 'Tower 3, Zone 1',
            operator: 'Karen Chilton',
            work_type:'Material Transport',
            alert_type_level:'Safety Infraction | Severity Level - 1 | Cam#DID1',
            alert_date_time: '02-11-2024 | 09:00 AM',

        },
        {
            name:'Unauthorized Visit',
            id: 2,
            image: 'alert_icon.svg',
            capture_image:'missing-helmet-img.png',
            capture_video:'missing-helmet.mp4',
            cp_image:'sm-cp-img.png',
            loc: { x: '-69', y: '-186' },
            label: 'Packaging, Zone 1',
            operator: 'Fed Chilton',
            work_type:'Material Transport',
            alert_type_level:'Safety Infraction | Severity Level - 2 | Cam#DID2',
            alert_date_time: '10-03-2024 | 03:00 PM',


        },
        {
            name:'Missing Gloves',
            id: 3,
            image: 'alert_icon.svg',
            capture_image:'missing-helmet-img.png',
            capture_video:'missing-helmet.mp4',
            cp_image:'sm-cp-img.png',
            loc: { x: '-131', y: '-85' },
            label: 'Construction, Zone 1',
            operator: 'Peter Bishop',
            work_type:'Material Transport',
            alert_type_level:'Safety Infraction | Severity Level - 3 | Cam#DID3',
            alert_date_time: '10-03-2024 | 07:00 AM',
        },
        {
            name:'Missing Helmet',
            id: 6,
            image: 'alert_icon.svg',
            capture_image:'missing-helmet-img.png',
            capture_video:'missing-helmet.mp4',
            cp_image:'sm-cp-img.png',
            loc: { x: '4', y: '52' },
            label: 'Construction, Zone 1',
            operator: 'Pannola Van',
            work_type:'Material Transport',
            alert_type_level:'Safety Infraction | Severity Level - 3 | Cam#DID9',
            alert_date_time: '10-03-2024 | 08:00 PM',
        },
        {
            name:'Unauthorized Visit',
            id: 8,
            image: 'alert_icon.svg',
            capture_image:'missing-helmet-img.png',
            capture_video:'missing-helmet.mp4',
            cp_image:'sm-cp-img.png',
            loc: { x: '96', y: '120' },
            label: 'Tower 2, Zone 3',
            operator: 'Wesly Tim',
            work_type:'Material Transport',
            alert_type_level:'Safety Infraction | Severity Level - 1 | Cam#DID2',
            alert_date_time: '10-03-2024 | 09:25 PM',

        },
        {
            name:'Missing Helmet',
            id: 9,
            image: 'alert_icon.svg',
            capture_image:'missing-helmet-img.png',
            capture_video:'missing-helmet.mp4',
            cp_image:'sm-cp-img.png',
            loc: { x: '-176', y: '309' },
            label: 'Packaging, Zone 1',
            operator: 'Jack Fred',
            work_type:'Material Transport',
            alert_type_level:'Safety Infraction | Severity Level - 3 | Cam#DID3',
            alert_date_time: '09-03-2024 | 09:35 PM',
        },
    ];

    const [activeIncident, setActiveIncident] = React.useState();


    const [showIncidentDetail,setShowIncidentDetail] = React.useState(false);
    const handleInfoIconClick = (e) => {
        //todo
        setShowIncidentDetail(true);
    }
    const handleDetailCardClose = () => {
        setShowIncidentDetail(false);
    }
    return (
        <>
            {showIncidentDetail &&
                <IncidentDetails activeIncident={activeIncident} handleDetailCardClose={handleDetailCardClose}/>}
            <div className={style.cardContainerIncident} >
                {alertData.map((alert, key) =>
                    <div className='worker-card-incident' key={key}>
                        <div className='wc-container-incident'>
                            <div className='wc-wrapper-2-incident'>
                                <div className='det-1'>
                                    <div className="head"><p className='w-name'>{alert.name}</p></div>
                                    <div className="info-logo"><img src={infocap} alt="" className="pointer" onClick={(e)=>{
                                        handleInfoIconClick(e);
                                        setActiveIncident(alert);
                                    }}/></div>
                                    
                                </div>
                                <div className='det-2'>
                                    <p className='w-id'>{alert.alert_type_level}</p>
                                </div>

                                <div className='det-3'>
                                    <div className="w1"><p className='work-a'><img src={user} alt=""/> {alert.operator}</p></div>
                                    <div className="w2"><p className='work-a'><img src={mtype} alt=""/> {alert.work_type}</p></div>
                                    
                                </div>
                                <div className='det-4'>

                                <div className="w1"><p className='work-a'><img src={lz} alt=""/> {alert.label}</p></div>
                                <div className="w2"><p className='work-a'><img src={timeline} alt=""/> {alert.alert_date_time}
                                </p></div>
                                    
                                    
                                </div>
                            </div>
                        </div>


                    </div>

                )}
            </div>
        </>
    )
}

export default IncidentList
