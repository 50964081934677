import React, { Fragment, useEffect, useRef, useState } from "react";
import BotHero from "./BotHero";
import pbImage from "../images/ChatScreen/pb-img.png";
import vector from "../images/ChatScreen/Vector.png";
import ButtonDisabled from "../images/ChatScreen/ButtonDisabled.svg";
import Button from "../images/ChatScreen/Button.png";
import ChatShimmer from "./ChatShimmer";
import appLogo from "../images/logo.jpg";
import { useDispatch, useSelector, useStore } from "react-redux";
import {addChat, reset} from "../store/chatTimeline";
import { showSnackBar } from "../util/showSnackBar";
import { componentMap, Constants } from '../Constants'
import EmployeeList from "./employee/EmployeeList";
import Floormap from "./Floormap";
import AlertFloorMap from "./AlertFloorMap";
import WorkPathMap from "./employee/WorkPathMap";
import SafetyInfractions from "./incident/SafetyInfractions";
import EquipmentList from "./equipment/EquipmentList";
import EquipmentAnalyticsChart from "./Analytics/EquipmentAnalyticsChart";
import SafetyInfractionsChart from "./Analytics/SafetyInfractionsChart";
import WorkerAnalyticsChart from "./Analytics/WorkerAnalyticsChart";
import workerProfile from '../data/workerProfile.json';
import equipmentProfile from '../data/equipmentProfile.json';

const ChatWrapper = ({ pokeSibling }) => {
  // eslint-disable-next-line no-empty-pattern
  const [] = useState(Constants.API_URL);
  const chats = useSelector((state) => state.chats);

  const dispatch = useDispatch();
  const store = useStore();
  const [chatBucket, setChatBucket] = useState(chats);

  const [query, setQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasTimeLine, setHasTimeLine] = useState(false);

  const [tags] = useState([
    "Show people who are inside facility premises",
    "Show all the equipment in facility map",
    "Show all alerts for the last 24 hours on facility map",
    "Show last 7 days analytics for Peter Bishop",
  ]);
  const handleQueryBoxChange = (e) => {
    setQuery(e.target.value);
  };
  const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };
    return [htmlElRef, setFocus];
  };

  // const [workerDataList,setWorkerDataList] = useState( store.getState().allData);
  console.debug('workerProfile?.workerProfiles',workerProfile?.workerProfiles);
  const [workerDataList] = useState(workerProfile?.workerProfiles ?? []);
  const [equipmentDataList] = useState(equipmentProfile.equipmentProfile ?? []);
 
  useEffect(() => {
    setTimeout(() => {
      // setWorkerDataList(store.getState().allData);
      // setEquipmentDataList(store.getState().allData);
    }, 2000);
  }, [store]);

  const handleFormatChatResponse = () => {
    setTimeout(() => {
      const storeState = store.getState();
      setChatBucket(storeState.chats);
      if (storeState.chats.length > 0) {
        setHasTimeLine(true);
      }
      scrollToBottom("chat_timeline");
      executeScroll();
      pokeSibling();
      setQuery('');
    }, 400);
  }

  const handleInvalidChatResponse = (query) => {
    const newChatFromUser = {from: "user", msg: query};
    dispatch(addChat(newChatFromUser));
    const newChatFromSystem = {
      from: "system",
      msg: 'This data is not available',
      component_name: '',
      meta: {}
    };
    dispatch(addChat(newChatFromSystem));
  }
  
  const handleValidChatResponse = (filter) => {
    const newChatFromUser = {from: "user", msg: filter.length > 0 ? filter[0].query : '', meta: {}};
    dispatch(addChat(newChatFromUser));
    const newChatFromSystem = {
      from: "system",
      msg: filter.length > 0 ? filter[0].response_msg : '',
      component_name: filter[0].component_name,
      meta: filter.length > 0
          ? (
              filter[0].meta.type === 'equipment'
                  ? {'type': 'equipment'}
                  : filter[0].meta.type === 'employee'
                      ? {'type': 'employee'}
                      : (filter[0].meta.filterType === 'last_seven_days'
                          ? {'filterType': 'last_seven_days'}
                          : {})
          ) : {}

  };
    dispatch(addChat(newChatFromSystem));
  }
  
  
  const handleSend = (e) => {

    try {
      if (query !== "" || e.key === undefined) {
        if ((query === '' && e.key !== 'keydown') || (query === '' && e.key === undefined) || (query !== '' && (e.type === 'click' || e.key === 'Enter'))) {
          if (e !== '' || (e !== undefined ? (e.key === "Enter" || e.type === "click") : false)) {
            const filter = componentMap.filter(obj => Object.keys(obj).some(key => obj[key].toString().toLowerCase().includes(query !== '' ? query.toLowerCase() : e.toLowerCase())));
            const filteredQuery = filter.length > 0 ? filter[0].query : '';
            if (filteredQuery !== '') {
              handleValidChatResponse(filter);
            } else {
              handleInvalidChatResponse(query !== '' ? query.toLowerCase() : e.toLowerCase());
              //processQuery();
            }
            handleFormatChatResponse();
          }
        }
      } else {
        if (e.key === "Enter" || e.type === "click") {
          setErrorMessage(`Query can"t be empty`);
          showSnackBar("-280px");
        }
      }
    }catch (e){
      setErrorMessage(`Query can"t be empty`);
      showSnackBar("-280px");
    }
  };



  const scrollToBottom = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  };

  const [inputRef] = useFocus();
  const myRef = useRef(null);
  const executeScroll = () => {
    myRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    executeScroll();
  }, [chatBucket]);

  const handleClearChat = () => {
    dispatch(reset());
    setTimeout(() => {
      const storeState = store.getState();
      setChatBucket(storeState.chats);
      if (storeState.chats.length > 0) {
        setHasTimeLine(true);
      }
      scrollToBottom("chat_timeline");
      executeScroll();
      pokeSibling();
      setIsLoading(false);
      setHasTimeLine(false);
    }, 400);
  }
  return (
    <>
      <main className="main-content-alets">
        {!hasTimeLine && <BotHero isLoading={isLoading} />}
        {hasTimeLine && (
          <div className="page-card">
            <>
              {chatBucket.map((cd, key) => (
                  <Fragment key={key}>
                    <div className="page-card-A">
                      {cd.from === "system" && (
                          <img
                              src={appLogo}
                              alt="GAI Logo"
                              className="chat_system"
                          />
                      )}
                      {cd.from === "system" && cd.msg !== undefined && JSON.parse(cd.msg)}
                      {cd.from === "user" && <img src={pbImage} alt=""/>}
                      {cd.from === "user" && cd.msg !== undefined && JSON.parse(cd.msg)}
                      <div
                          ref={myRef}
                          id={"chat_timeline"}
                          style={{marginBottom: "10px"}}
                      ></div>
                      <div style={{marginTop: "80px"}}></div>
                    </div>
                    {cd.from === "system" && cd.component_name === 'EmployeeList' &&
                        <EmployeeList workerList={workerDataList}/>}
                    {cd.from === "system" && cd.component_name === 'Floormap' && cd.meta?.type === 'employee' &&
                        <Floormap workerList={workerDataList} type={cd.meta?.type}/>}
                    {cd.from === "system" && cd.component_name === 'Floormap' && cd.meta?.type === 'equipment' &&
                        <Floormap equipmentList={equipmentDataList} type={cd.meta?.type}/>}
                    {cd.from === "system" && cd.component_name === 'SafetyInfractions' && <SafetyInfractions/>}
                    {cd.from === "system" && cd.component_name === 'WorkPathMap' && <WorkPathMap/>}
                    {cd.from === "system" && cd.component_name === 'EquipmentList' && <EquipmentList equipmentListData={equipmentDataList}/>}
                    {cd.from === "system" && cd.component_name === 'AlertFloorMap' && <AlertFloorMap/>}
                    {cd.from === "system" && cd.component_name === 'EquipmentAnalyticsChart' && cd.meta?.filterType === 'last_seven_days' &&
                        <EquipmentAnalyticsChart filterType={cd.meta?.filterType}/>}
                    {cd.from === "system" && cd.component_name === 'WorkerAnalyticsChart' && cd.meta?.filterType === 'last_seven_days' && <WorkerAnalyticsChart filterType={cd.meta?.filterType}/>}
                    {cd.from === "system" && cd.component_name === 'SafetyInfractionsChart' && cd.meta?.filterType === 'last_seven_days' &&
                        <SafetyInfractionsChart filterType={cd.meta?.filterType}/>}
                  </Fragment>
              ))}


              {isLoading && <ChatShimmer/>}

            </>
          </div>
        )}

        {!hasTimeLine && isLoading && <ChatShimmer/>}

        <div className="section-two">
          <div className="history-data">
            {tags.map((tag, key) => (
              <div
                key={key}
                onClick={() => {
                  setQuery(tag);
                  setTimeout(() => {
                    handleSend(tag);
                  }, 400);
                  // setInputFocus();
                }}
              >
                {tag}
              </div>
            ))}
          </div>
          <div className="query-wrapper">
            <div className="query-inner-wrapper">
              <input
                className="enter-query-input"
                placeholder="Enter your query"
                value={query}
                autoFocus
                onChange={handleQueryBoxChange}
                onKeyDown={handleSend}
                ref={inputRef}
              />
              <img
                className="enter-query-icon"
                src={vector}
                onClick={handleSend}
                alt="send button"
              />
            </div>
            {chatBucket.length > 0 && <img className="plus-img" src={Button}  style={{cursor:"pointer"}} alt="upload option" onClick={handleClearChat}/>}
            {chatBucket.length === 0 && <img className="plus-img" src={ButtonDisabled} alt="upload option"/>}

          </div>
        </div>
      </main>
      <div id="snackbar">{errorMessage}</div>
    </>
  );
};

export default ChatWrapper;


