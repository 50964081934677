import React, { useEffect, useState } from 'react';

const BlurryLoadingImage = ({
    preview,
    image,
    alt,
    imageStyleClass,
    divStyleClass,
    bgColor = 'transparent',
    height,
    width
}) => {
    const [currentImage, setCurrentImage] = useState(preview);
    const [loading, setLoading] = useState(true);

    const fetchImage = (src) => {
        const loadingImage = new Image();
        loadingImage.src = src;

        loadingImage.onload = () => {
            setCurrentImage(src);
            setLoading(false);
        };
    };

    useEffect(() => {
        setCurrentImage(preview);
        setLoading(true);
        fetchImage(image);
    }, [image, preview]);

    return (
        <div className={divStyleClass} style={{ overflow: 'hidden', height, width }}>
            <img
                style={{
                    filter: loading ? 'blur(20px)' : 'none',
                    transition: '0.35s filter linear',
                    width: '100%',
                    height: '100%',
                    background: bgColor,
                    objectFit: 'cover',
                }}
                src={currentImage}
                alt={alt}
                className={imageStyleClass}
            />
        </div>
    );
};

export default BlurryLoadingImage;