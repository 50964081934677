// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

.floor-container {

    display: flex;
    width: 100%;
    padding: 20px 25px;
    background-color: #1a1a1a;
    flex-direction: column;
}

.floor-map {
    max-width: 1241px;
    height: 563px;
    background: #1E2223;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    margin-top: 15px;

}

.inner-floor {
    position: relative;
}

.workers {
    /*position: absolute;*/
    position: relative;
}

img.wk-1 {
    cursor: pointer;
    z-index: 98;
    position: absolute;
    right: 18rem;
    bottom: -1rem;
}

img.wk-1:hover {
    cursor: pointer;
    z-index: 98;
    position: absolute;
    right: 18rem;
    bottom: -1rem;
    filter: drop-shadow(4px 4px 4px #b3cf2d);
    transform: scale(1.5);
}
.workers img{
    width: 35px;
}

.eq-hov:hover{
    cursor: pointer;
    z-index: 98;
    position: absolute;
    right: 18rem;
    bottom: -1rem;
    filter: drop-shadow(4px 4px 4px #e0cb27) !important;
    transform: scale(1.5);
}`, "",{"version":3,"sources":["webpack://./src/components/Floormap.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;;AAE1B;;AAEA;;IAEI,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;;AAEpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,wCAAwC;IACxC,qBAAqB;AACzB;AACA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,mDAAmD;IACnD,qBAAqB;AACzB","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n\n}\n\n.floor-container {\n\n    display: flex;\n    width: 100%;\n    padding: 20px 25px;\n    background-color: #1a1a1a;\n    flex-direction: column;\n}\n\n.floor-map {\n    max-width: 1241px;\n    height: 563px;\n    background: #1E2223;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-left: 16px;\n    margin-top: 15px;\n\n}\n\n.inner-floor {\n    position: relative;\n}\n\n.workers {\n    /*position: absolute;*/\n    position: relative;\n}\n\nimg.wk-1 {\n    cursor: pointer;\n    z-index: 98;\n    position: absolute;\n    right: 18rem;\n    bottom: -1rem;\n}\n\nimg.wk-1:hover {\n    cursor: pointer;\n    z-index: 98;\n    position: absolute;\n    right: 18rem;\n    bottom: -1rem;\n    filter: drop-shadow(4px 4px 4px #b3cf2d);\n    transform: scale(1.5);\n}\n.workers img{\n    width: 35px;\n}\n\n.eq-hov:hover{\n    cursor: pointer;\n    z-index: 98;\n    position: absolute;\n    right: 18rem;\n    bottom: -1rem;\n    filter: drop-shadow(4px 4px 4px #e0cb27) !important;\n    transform: scale(1.5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
