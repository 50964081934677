import React, {useEffect, useState} from 'react'
import style from './Login.module.css';
import {useCookies} from "react-cookie";
import {useNavigate} from "react-router-dom";
import logo from "../images/login-logo.svg";
import "../pages/Login.css";
import btlogo from "../images/bottom-logo.svg";
import msg from "../images/login-lock.svg";
import lock from "../images/login-msg.svg";
import {showSnackBar} from "../util/showSnackBar";
import {Constants}  from '../Constants'

const Login = () => {
    let navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [cookies, setCookie] = useCookies([Constants.COOKIE_TOKEN]);

    const handleFormSubmit = (e) => {
        if (
            username.trim() === Constants.EMAIL &&
            password.trim() === Constants.PASSWORD
        ) {
            // authenticate and redirect to home
            let encodedValue = btoa(username + "_" + password);
            setCookie(Constants.COOKIE_TOKEN, encodedValue);
            return navigate("/");
        } else {
            //show error
            if (e.key === "Enter" || e.target.type === "button") {
                if (username.trim() === "") {
                    setErrorMessage("Email can't be empty.");
                } else if (password.trim() === "") {
                    setErrorMessage("Password can't be empty.");
                } else if (username.trim() !== "admin" && password.trim() !== "admin") {
                    setErrorMessage("Email or Password is wrong.");
                }
                showSnackBar("-125px");
            }
        }
    };

    const handleUserName = (e) => {
        setUsername(e.target.value);
    };

    const handlePassword = (e) => {
        setPassword(e.target.value);
    };
    const [errorMessage, setErrorMessage] = useState("");
    const [loginStateChecked, setLoginStateChecked] = useState(false);

    useEffect(() => {
        if (cookies.token === undefined) {
            setLoginStateChecked(true);
        }else {
            let decodedValue = window.atob(cookies.token);
            if (decodedValue === 'admin_admin') {
                return navigate("/");
            }
        }
        setLoginStateChecked(true);
    }, [cookies])
    return (

        <>
            {!loginStateChecked && <p>Loading</p>}
            {loginStateChecked && <div className="form-container">
                <form
                    action="/"
                    method="post"
                    className="login-form"
                    onClick={handleFormSubmit}
                >
                    <div className="login-logo">
                        <img src={logo} alt=""/>
                    </div>

                    <div className={style.container}>
                        <div className="Heading">
                            <p>Welcome</p>
                        </div>
                        <label htmlFor="uname">Your Email</label>
                        <div className="inputBox">
                            <input
                                type="text"
                                placeholder="Enter Your Email"
                                name="uname"
                                required
                                onChange={handleUserName}
                                value={username}
                                onKeyDown={handleFormSubmit}
                                autoComplete="new-password"
                            />
                            <img src={msg} alt=""/>
                        </div>
                        <label htmlFor="psw">Password</label>
                        <div className="inputBox">
                            <input
                                type="password"
                                placeholder="Enter Password"
                                name="psw"
                                required
                                onChange={handlePassword}
                                value={password}
                                onKeyDown={handleFormSubmit}
                                autoComplete="new-password"
                            />
                            <img src={lock} alt=""/>
                        </div>
                        <button type="button" onClick={handleFormSubmit}>
                            Login
                        </button>
                    </div>
                </form>

                <div className="bottom-text">
                    <p>
                        Powered by &nbsp; <img src={btlogo} alt=""/> &nbsp; &copy; 2024. All
                        Rights Reserved
                    </p>
                </div>
                <div id="snackbar" className={style.snackbar}>
                    {errorMessage}
                </div>
            </div>}
        </>
    );
};

export default Login;
