import React, {useEffect} from 'react'
import "./CardInfo.css"
import loc from "../../images/location-logo.png"
import info from "../../images/info-logo.png"
import EmployeeDetails from './EmployeeDetails';


function EmployeeList({workerList}) {
    const workerData = [
        {
            name: 'Karen Chilton',
            id: 1,
            image: 'wrkr1.svg',
            image_raw: 'Karen Chilton.svg',
            loc: {x: '-44', y: '-334'},
            label: 'Welding Area, Zone 1',
            team: 'Team 1',
            designation: 'WID#2144125 | Field Engg.',
            worktype: 'Material Transport',
            machine: 'Forklift 1'
        },
        {
            name: 'Jack Fred',
            id: 2,
            image: 'wrkr2.svg',
            image_raw: 'Jack Fred.svg',
            loc: {x: '-91', y: '-213'},
            label: 'Construction, Zone 1',
            team: 'Team 1',
            designation: 'WID#2143135 | Field Engg.',
            worktype: 'Material Transport',
            machine: 'Forklift 1'
        },
        {
            name: 'Wesly Tim',
            id: 3,
            image: 'wrkr3.svg',
            image_raw: 'Wesly Tim.svg',
            loc: {x: '90', y: '-212'},
            label: 'Packaging, Zone 1',
            team: 'Team 1',
            designation: 'WID#2143295 | Electrician',
            worktype: 'Material Transport',
            machine: 'Forklift 1'
        },
        {
            name: 'Fed Chilton',
            id: 4,
            image: 'wrkr4.svg',
            image_raw: 'Fed Chilton.svg',
            loc: {x: '-227', y: '42'},
            label: 'Tower 3, Zone 1',
            team: 'Team 1',
            designation: 'WID#2143195 | Forklift Opr.',
            worktype: 'Material Transport',
            machine: 'Forklift 1'
        },
        {
            name: 'Peter Bishop',
            id: 5,
            image: 'wrkr5.svg',
            image_raw: 'Peter Bishop.svg',
            loc: {x: '60', y: '-9'},
            label: 'Construction, Zone 1',
            team: 'Team 1',
            designation: 'WID#2143295 | Field Engg.',
            worktype: 'Material Transport',
            machine: 'Forklift 1'
        },
        {
            name: 'Pannola Van',
            id: 6,
            image: 'wrkr6.svg',
            image_raw: 'Pannola Van.svg',
            loc: {x: '-127', y: '163'},
            label: 'Tower 1, Zone 2',
            team: 'Team 1',
            designation: 'WID#2243195 | Crane Opr.',
            worktype: 'Material Transport',
            machine: 'Forklift 1'
        },
    ];

    const [activeWorker, setActiveWorker] = React.useState();


    const [showEmployeeDetail,setShowEmployeeDetail] = React.useState(false);
    const handleInfoIconClick = (e) => {
        //todo
        setShowEmployeeDetail(true);
    }
    const handleDetailCardClose = () => {
        setShowEmployeeDetail(false);
    }

    useEffect(() => {
        const el = document.getElementsByClassName('EmployeeList')[0];
        const observer = new window.IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                return
            }
            setShowEmployeeDetail(false);
        }, {
            root: null,
            threshold: 0.1, // set offset 0.1 means trigger if atleast 10% of element in viewport
        })

        observer.observe(el);

    }, []);


    return (
        <>
            {showEmployeeDetail && <EmployeeDetails activeEmployee={activeWorker} handleDetailCardClose={handleDetailCardClose}/>}
            <div className='emp_container EmployeeList'>
                <div className='emp_cards'>
                    {workerList.map((worker,key) =>
                        <div className='card_emp' key={key}>
                            <img className='info-img cursor' src={info} alt="" onClick={(e)=>{
                                handleInfoIconClick(e);
                                setShowEmployeeDetail(false);
                                setTimeout(()=>{
                                    setActiveWorker(worker);
                                    setShowEmployeeDetail(true);
                                },400);
                            }}/>
                            <img className='emp-img' src={require(`../../images/${worker?.image_raw}`)} alt=""/>
                            <p className='emp-name'>{worker.name}</p>
                            <p className='emp-id'>{worker.employee_id} |  {worker.role}</p>
                            <p className='emp-loaction'><img src={loc} alt=""/> {worker.current_location}</p>
                        </div>
                    )}

                </div>
            </div>
        </>
    )
}

export default EmployeeList
