import React from 'react'
import sort from "../images/sort.png";
import msg from "../images/msg.png";

const RightSideBar = ({chatBucket}) => {
  return (
      <aside className="right-sidebar">
          <div className="tab">
              <button className={chatBucket.length > 0 ? `tablinks-active`:'tablinks'} onClick={()=>{}}>Past Queries</button>
              <button className="tablinks" onClick={()=>{}}>Saved Queries</button>
          </div>
          <div className="search-box">
              <form className="search-form">
                  <label htmlFor="default-search" className="label">Search</label>
                  <div className="input-container">
                      <div className="icon-container">
                          <svg className="icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                               viewBox="0 0 20 20">
                              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                    strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                          </svg>
                      </div>
                      <input type="search" id="default-search" className="search-input" placeholder="Search..."
                             required/>
                      <img className="search-button" src={sort} alt={'search button'}/>
                  </div>
              </form>
              {chatBucket.length > 0 &&
                  <div className="msg-card">
                      <div className="msg-card-inner">
                          <div className="msg-box-img"><img src={msg} alt="msg img"/></div>
                          <div className="msg-box-p">
                              <p className="msg-p truncate" title={JSON.parse(chatBucket[0].msg)}>{JSON.parse(chatBucket[0].msg)}...</p>
                              <p className="msg-date">{(new Date()).toLocaleString()}</p>
                          </div>
                      </div>
                  </div>
              }
          </div>
      </aside>
  )
}

export default RightSideBar
