// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-container{
    max-width: 1200px;
    margin-top: 2px;
    flex-wrap: wrap;
    display: flex;
    height: 100px;
    /*margin-bottom: 2px;*/
    margin-bottom: 100px;
}

.chat-loader{
    width:969px ;
    display: inline-block;
    overflow: hidden;
    /* background-color: #1A1F22; */
    margin-left: 60px;

}
.bar-1{
    height: 20px;
    background-color:#1A1F22;
    width: 969px;
    margin-bottom: 10px;
    border-radius: 2px;
}
.bar-2{
    height: 20px;
    background-color: #1A1F22;
    width: 810px;
    margin-bottom: 10px;
    border-radius: 2px;
}
.bar-3{
    height: 20px;
    background-color:#1A1F22;
    width: 665px;
    margin-bottom: 10px;
    border-radius: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatShimmer.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,gBAAgB;IAChB,+BAA+B;IAC/B,iBAAiB;;AAErB;AACA;IACI,YAAY;IACZ,wBAAwB;IACxB,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,wBAAwB;IACxB,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".chat-container{\n    max-width: 1200px;\n    margin-top: 2px;\n    flex-wrap: wrap;\n    display: flex;\n    height: 100px;\n    /*margin-bottom: 2px;*/\n    margin-bottom: 100px;\n}\n\n.chat-loader{\n    width:969px ;\n    display: inline-block;\n    overflow: hidden;\n    /* background-color: #1A1F22; */\n    margin-left: 60px;\n\n}\n.bar-1{\n    height: 20px;\n    background-color:#1A1F22;\n    width: 969px;\n    margin-bottom: 10px;\n    border-radius: 2px;\n}\n.bar-2{\n    height: 20px;\n    background-color: #1A1F22;\n    width: 810px;\n    margin-bottom: 10px;\n    border-radius: 2px;\n}\n.bar-3{\n    height: 20px;\n    background-color:#1A1F22;\n    width: 665px;\n    margin-bottom: 10px;\n    border-radius: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
