import { createSlice } from "@reduxjs/toolkit";

const allDataSlice = createSlice({
    name: "allData",
    initialState: [],
    reducers: {
        addData: (state, action) => {
            const newData = {
                data: action.payload.data,
            };
            state.push(newData);
        },
        addAllData: (state, action) => {
            // eslint-disable-next-line array-callback-return
            action.payload.data.map((t) => {
                state.push(t);
            })
            // state = action.payload.data;
        },
        reset: (state) => {
            state.length = 0;
        },
    },
});
export const { addData,addAllData,reset} = allDataSlice.actions;
export default allDataSlice.reducer;