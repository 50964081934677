// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LogoWrapper_logoLine__tN2WR:hover {
    width: 40px;
    height: 14px;
}
.LogoWrapper_logoLine__tN2WR {
    width: 40px;
    height: 14px;
}

.LogoWrapper_logoLineParent__Ulah-:hover {
    margin-left: 5px;
}
.LogoWrapper_logoLineParent__Ulah- {
    margin-left: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/LogoWrapper.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".logoLine:hover {\n    width: 40px;\n    height: 14px;\n}\n.logoLine {\n    width: 40px;\n    height: 14px;\n}\n\n.logoLineParent:hover {\n    margin-left: 5px;\n}\n.logoLineParent {\n    margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoLine": `LogoWrapper_logoLine__tN2WR`,
	"logoLineParent": `LogoWrapper_logoLineParent__Ulah-`
};
export default ___CSS_LOADER_EXPORT___;
