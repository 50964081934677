import React, {useState} from 'react'
import './EquipmentList.css'
import cblogo from "../../images/cb-logo.svg"
import infocap from "../../images/info-cap.svg"
import loc from "../../images/location-logo.png"
import EquipmentDetails from './EquipmentDetails'
import equipmentProfiles  from '../../data/equipmentProfile.json';

function EquipmentList() {

    // const equipmentListData = [
    //     {
    //         name: 'Forklift 1',
    //         id: 1,
    //         operator_name_id: 'EID#2143195 | Fed Chilton',
    //         loc_label: 'Tower 3, Zone 1',
    //         image: 'normal_veh.svg',
    //         image_raw: 'forklift1.svg',
    //     },
    //     {
    //         name: 'Forklift 2',
    //         id: 2,
    //         operator_name_id: 'EID#2143195 | Pannola Van',
    //         loc_label: 'Tower 1, Zone 2',
    //         image: 'normal_veh.svg',
    //         image_raw: 'forklift1.svg',
    //     },
    //     {
    //         name: 'Excavator 1',
    //         id: 3,
    //         operator_name_id: 'EID#2143195 | Jack Fred',
    //         loc_label: 'Tower 3, Zone 1',
    //         image: 'normal_veh.svg',
    //         image_raw: 'forklift1.svg',
    //     },
    //     {
    //         name: 'Loader#243',
    //         id: 4,
    //         operator_name_id: 'EID#2143195 | Alan Jan',
    //         loc_label: 'Tower 3, Zone 1',
    //         image: 'normal_veh.svg',
    //         image_raw: 'forklift1.svg',
    //     },
    //     {
    //         name: 'Scissor Lift 1',
    //         id: 5,
    //         operator_name_id: 'EID#2143195 | Fed Chilton',
    //         loc_label: 'Tower 3, Zone 1',
    //         image: 'normal_veh.svg',
    //         image_raw: 'forklift1.svg',
    //     },
    //     {
    //         name: 'Crane 1',
    //         id: 6,
    //         operator_name_id: 'EID#2143195 | Fed Chilton',
    //         loc_label: 'Tower 3, Zone 1',
    //         image: 'normal_veh.svg',
    //         image_raw: 'forklift1.svg',
    //     },
    //     {
    //         name: 'Crane 2',
    //         id: 7,
    //         operator_name_id: 'EID#2143195 | Fed Chilton',
    //         loc_label: 'Tower 3, Zone 1',
    //         image: 'normal_veh.svg',
    //         image_raw: 'forklift1.svg',
    //     },
    //
    // ];

    const [equipmentListData] = useState(equipmentProfiles.equipmentProfile)

    const [activeEquipment, setActiveEquipment] = React.useState();

    const [showEquipmentDetail, setShowEquipmentDetail] = React.useState(false);
    const handleInfoIconClick = (e) => {
        //todo
        setShowEquipmentDetail(true);
    }
    const handleDetailCardClose = () => {
        setShowEquipmentDetail(false);
    }

    return (
        <>
            {showEquipmentDetail &&
                <EquipmentDetails activeEquipment={activeEquipment} handleDetailCardClose={handleDetailCardClose} />}
            <div className='eqli-container'>
                <div className="eq-lists" >
                    {equipmentListData.map((equipment, key) =>

                        <div className="eq-list-wrap" key={key}>
                            <div className="eq-li1">
                                <div className="eq-head"><p className='eq-name'>{equipment.equipmentName}</p></div>
                                <div className="info-logo"><img src={infocap} alt="" className="pointer" onClick={(e) => {
                                    handleInfoIconClick(e);
                                    setActiveEquipment(equipment);
                                }} /></div>
                                
                            </div>
                            <div className="eq-li2">
                                <p className='eqli-id'>{equipment.assignedOperator.name}</p>
                            </div>
                            <div className="eqli-loc">
                                <img src={loc} alt="" />
                                <p className='eqli-loc-p'>{equipment.currentStatus.location}</p>
                            </div>
                        </div>
                    )}
                </div>


            </div>
        </>
    )
}

export default EquipmentList

