import React from "react";
import { Message2 } from "../icons/Message2";
import compassDisabled from "../images/leftMenu/compassDisabled.svg";
import folderDisabled from "../images/leftMenu/folderDisabled.svg";
import bookmarkDisabled from "../images/leftMenu/bookmarkDisabled.svg";
import usersDisabled from "../images/leftMenu/usersDisabled.svg";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import logout from "../images/logout.png";
import {Constants}  from '../Constants'
import style from './LeftMenu.module.css';
function LeftMenu() {
  let navigate = useNavigate();

  const [, , removeCookie] = useCookies([Constants.COOKIE_TOKEN]);
  const handleLogout = () => {
    removeCookie(Constants.COOKIE_TOKEN);
    return navigate("/login");
  };

  return (
    <aside className="sidebar">
      <div className="sidebar-icons">
        <div className={`icon ${style.enabledIcon}`}>
          <Message2 />
          <div className="tooltip tpright query">Queries</div>
        </div>
        <div className={`icon ${style.disabledIcon}`}>
          <Link to={"/#"}>
            <img src={compassDisabled} alt="Settings Icon" />
          </Link>
        </div>
        <div className={`icon ${style.disabledIcon}`}>
          <Link to={"/#"}>
            <img src={folderDisabled} alt="Settings Icon" />
          </Link>
        </div>
        <div className={`icon ${style.disabledIcon}`}>
          <Link to={"/#"}>
            <img src={bookmarkDisabled} alt="Settings Icon" />
          </Link>
        </div>

        <div className={`icon ${style.disabledIcon}`}>
          <Link to={"/#"}>
            <img src={usersDisabled} alt="Settings Icon" />
          </Link>
        </div>

        <div className={`icon ${style.enabledIcon}`}>
          <Link to={"/#"}>
            <img onClick={handleLogout} src={logout} alt="Settings Icon" />
            <div className="tooltip tpright logout">Logout</div>
          </Link>
        </div>
      </div>
    </aside>
  );
}

export default LeftMenu;
