import React, {Fragment, useEffect, useState} from 'react'
import "./EmployeeCard.css"
import EmployeeDetails from "./EmployeeDetails"
import imgcap from "../../images/img-cap.svg"
import vcap from "../../images/video-cap.svg"
import infocap from "../../images/info-cap.svg"
import equp from "../../images/forklift.svg"
import mtype from "../../images/todo.svg"
import team1 from "../../images/teams-cap.svg"
import lz from "../../images/locationlg.svg"
import lg1 from "../../images/fitness-logo.svg"
import lg2 from "../../images/trustbd.svg"
import lg5 from "../../images/work-progress.svg"
import { PieChart } from '@mui/x-charts/PieChart';
// import workerImage from "../../images/Karen Chilton.svg"
import {getLatLong} from "../../util/latLong";

function EmployeeCard({ x, y, activeWorker,handleParentCardClose }) {

    const handleImageIconClick = (e) => {
        //todo
        console.debug('e', e);
    }

    const handleStreamIconClick = (e) => {
        //todo
        console.debug('e', e);
    }

    const [showEmployeeDetail, setShowEmployeeDetail] = React.useState(false);
    const handleInfoIconClick = (e) => {
        //todo
        setShowEmployeeDetail(true);
    }
    const handleDetailCardClose = () => {
        handleParentCardClose(true);
        setShowEmployeeDetail(false);
    }

    const [rightSideOverflow,setRightSideOverflow] = useState(0);
    const [,setLeftSideOverflow] = useState(0);
    useEffect(() => {
        const ele =  document.getElementsByClassName('worker-card')[0].getBoundingClientRect();
        const elParent = document.getElementsByClassName('main-content-alets')[0].offsetWidth;
        setLeftSideOverflow(0);
        setRightSideOverflow(0);
        if (ele.right >= elParent) {
            //overflowing right side
            setRightSideOverflow(ele.right - elParent)
            setLeftSideOverflow(0);
        } else if (ele.left >= elParent) {
            //overflowing left side
            setLeftSideOverflow(ele.left - elParent)
            setRightSideOverflow(0);
        }
    }, [activeWorker]);
    return (
        <>

            {showEmployeeDetail && <EmployeeDetails activeEmployee={activeWorker} handleDetailCardClose={handleDetailCardClose} />}
            <div className='card-container'>
                {/*<div className='worker-card' style={{ zIndex: '9998', position: 'absolute', left: (x + 30) + 'px', top: (y - 60) + 'px' }}> */}
                <div className='worker-card' style={{ zIndex: '997', position: 'absolute', top: (parseInt(getLatLong(activeWorker.worker_location).num1) + 150) + 'px',  left: (rightSideOverflow > 10 ? rightSideOverflow+330 : (parseInt(getLatLong(activeWorker.worker_location).num2)+50)) + 'px' }}>
                    <div className='wc-container'>
                        <div className='wc-wrapper-1'>
                            {/* <img src={require(`../../images/${activeWorker.image_raw}`)} alt="" style={{width:'65px'}} /> */}
                        </div>

                        <div className='wc-wrapper-2'>
                            <div className='det-1'>
                                <div className="e-head"><p className='w-name' title={activeWorker.name}>{activeWorker.worker_name}</p></div>
                                <div className="e-info-logo">
                                    <img src={imgcap} alt="" className="pointer" onClick={handleImageIconClick} />
                                    <img src={vcap} alt="" className="pointer" onClick={handleStreamIconClick} />
                                    <img src={infocap} alt="" className="pointer" onClick={handleInfoIconClick} />
                                </div>

                            </div>
                            <div className='det-2'>
                                <p className='w-id'>{activeWorker.worker_id}</p>
                                <p className='w-id'>{activeWorker.worker_designation}</p>
                            </div>

                            <div className='det-3'>
                                <div className="w-1"><p className='work-a' style={{textAlign:"left"}}><img src={equp} alt="" /> {activeWorker.equipment_name}</p></div>
                                <div className="w-2"> <p className='work-a' style={{textAlign:"left"}}><img src={mtype} alt="" /> {activeWorker.task_name}</p></div>


                            </div>

                            <div className='det-4'>
                                <div className="w-1">  <p className='work-a' style={{textAlign:"left"}}><img src={team1} alt="" /> {activeWorker.worker_team}</p></div>
                                <div className="w-2">  <p className='work-a' style={{textAlign:"left"}}><img src={lz} alt="" /> {activeWorker.worker_location_title}</p></div>
                            </div>
                        </div>
                    </div>

                    <div className='btm-wrapper'>
                        <div className='btm-sec1'>
                            <div className="tp1">
                                <img src={lg1} alt="" style={{paddingTop:"2px"}}/>
                                <div className="c-tooltip tpup ">Health Status - Good</div>
                            </div>
                            <div className="tp1">
                                <img src={lg2} alt="" style={{paddingTop:"1px"}}/>
                                <div className="c-tooltip tpup ">Safety Violation - No Violation</div>
                            </div>
                            <div className="tp1">
                                <p className='p-ninety-sm'>{activeWorker?.scores?.safetyScore}</p>
                                <div className="c-tooltip tpup ">Safety Score - Good</div>
                            </div>
                            <div className="progress tp1">
                                <p className='t-p'>{activeWorker.worker_completed_tasks}/{activeWorker.worker_total_tasks}</p>
                                <PieChart
                                    series={[
                                        {
                                            data: [
                                                { id: 0, value: 50, color: '#b3cf2d' },
                                                { id: 1, value: 50, color: '#959595' },

                                            ],
                                            innerRadius: 13, outerRadius: 16, cx: 15, cy: 22,

                                        },
                                    ]}
                                    width={40}
                                    height={55}

                                />
                                <div className="c-tooltip-c tpup-c ">Completed /Assigned</div>

                            </div>
                            <div className="tp1">
                                <img src={lg5} alt="" style={{paddingTop:"1px"}}/>
                                <div className="c-tooltip tpup ">Work Status - Idle</div>
                            </div>

                            <div className="tp1">
                                <p className='p-ninety-sm'>{activeWorker?.scores?.productivityScore}</p>
                                <div className="c-tooltip-n tpup-n ">Productivity Score - Good</div>
                            </div>
                        </div>

                        <div className="bar-parent-sm">
                            {/* <div className="bar-wrap-sm">
                                {activeWorker.taskJourney.map((task, index) =>
                                    <Fragment key={index}>
                                        {task.status === 'Completed' && <div className={`ct-1-sm`}></div>}
                                        {task.status === 'In Progress' && <div className={`ct-2-sm`}></div>}
                                        {task.status === 'Upcoming' && <div className={`ct-3-sm`}></div>}
                                    </Fragment>
                                )}
                                {activeWorker.taskJourney.map((task, index) =>
                                    <Fragment key={index}>
                                        {task.status === 'In Progress' && <div
                                            className={`marker-${index + 1}-sm inProgress-Marker`}
                                            key={index}>
                                            <div className="mkr-g-sm"></div>
                                        </div>}
                                        {task.status === 'Completed' && <div
                                            className={`marker-${index + 1}-sm completed-Maker`}
                                            key={index}>
                                            <div className="mkr-g-sm"></div>
                                        </div>}
                                        {task.status === 'Upcoming' && <div
                                            className={`marker-${index + 1}-sm upcoming-Marker`}
                                            key={index}>
                                            <div className="mkr-g-sm"></div>
                                        </div>}

                                    </Fragment>
                                )}


                                
                            </div> */}
                        </div>


                    </div>




                </div>
            </div>
        </>
    )
}

export default EmployeeCard
