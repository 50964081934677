import React, {useEffect} from 'react'
import {Link} from "react-router-dom";
import appLogo from "../images/logo.jpg";
import {GAI} from "../icons/GAI";
import style from "./LogoWrapper.module.css";

const LogoWrapper = () => {
    return (
        <aside className="sidebar-icon">
            <div className="logo">
                <Link to={'/'}>
                    <img src={appLogo} alt="GAI Logo"/>
                    <p className={style.logoLineParent}><GAI className={style.logoLine}></GAI></p>
                </Link>
            </div>
        </aside>
    )
}

export default LogoWrapper
